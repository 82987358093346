import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface IFilteredProp {
  propertyName: string,
}

@Component({
  selector: 'app-filter-buttons',
  templateUrl: './filter-buttons.component.html',
  styleUrls: ['./filter-buttons.component.scss']
})
export class FilterButtonsComponent{

  @Input() filterItems: {propertyName: string }[];
  @Input() listToFilter: any[];

  @Output() listFiltered: EventEmitter<{ status: boolean; filterType: string }> = new EventEmitter();

  activeFilter: string = 'Verified';

  constructor() {
    this.listFiltered = new EventEmitter();
  }

  filterClicked = (prop: {propertyName: string}) => {
    let filteredList;
    if (prop.propertyName === 'Verified') {
      filteredList = true;
    } else if (prop.propertyName === 'Unverified') {
      filteredList = false;
    }
    this.listFiltered.emit({ status : filteredList, filterType: prop.propertyName });
    this.activeFilter = prop.propertyName;
  }

}
