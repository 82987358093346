import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { Subscription } from 'rxjs/Subscription'
import { interval } from 'rxjs'


import * as _ from 'lodash'

import { AjaxService } from '../../../services/ajax.service'
import { InteractActions } from '../interact.actions'
import {
  Gender,
  IGroupsState,
  IInteractParticipant,
  InteractParticipantStatus, InteractParticipantStatusString,
  InteractQuestState,
  IParticipantStatus,
  IQuest
} from '../../../app.state'
import { ISortedProp } from '../../widgets/sort-buttons/sort-buttons.component'
import * as util from '../../../services/util.service'
import { AppActions } from 'app/app.actions';
import { IFilteredProp } from "../../widgets/filter-buttons/filter-buttons.component";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs/Observable";
import { SERVER_REMOTE_URL } from '../../../constants';

const trace = util.traceToggle(false)

/** Photos constants **/
const MAX_WIDTH = 200
const MAX_HEIGHT = 320

@Component({
  selector: 'sa-app-int-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class InteractParticipantsComponent implements OnInit, OnDestroy {
  listOptions: any[] = []
  selectAllChecked: boolean = false;
  selectedParticipants: { [key: string]: boolean } = {}
  ep: FormGroup
  uploadForm: FormGroup

  @select(['global', 'navOpened']) readonly navOpened$: Observable<boolean>
  @select(['groups']) readonly groupsTreeState$: Observable<IGroupsState>

  @Input() participants: IInteractParticipant[]
  @Input() filteredParticipants: IInteractParticipant[]
  @Input() activeQuestId: number
  @Input() requestToAdd: EventEmitter<number>
  @Input() statsArr: number[]
  @Input() authenticityToken: string
  @Input() participantStatus: IParticipantStatus
  @Input() isParticipantStatusDialogOpen: boolean
  @Input() activeQuest: IQuest
  @Output() filterApplied: EventEmitter<{ status: boolean; filter?: string }> = new EventEmitter();

  requestToAddSub: Subscription

  hpid: number
  editrid = -1

  sortProperties: ISortedProp[]
  filterProperties: IFilteredProp[]

  notstarted: number
  started: number
  completed: number
  unverified: number

  uploadboxIsOpen = false
  uploadPurpose = ''
  uploadBoxHeading = 'No Heading'
  waitForResponse = false
  modalMsg = ''
  intervalId: number
  m_subscription: Subscription
  allGroupArr: any[];

  /**
   * Constructor
   */
  constructor(
    private actions: InteractActions,
    private fb: FormBuilder,
    private as: AjaxService,
    private appActions: AppActions) {
    this.groupsTreeState$.subscribe(state => {
      this.allGroupArr = Object.values(state.groups).map((item: any) => item.name);
    })
    this.listOptions =
      [
        { name: 'Send personal report', checked: false, method: 'sendPersonalReport' }
      ]
  }

  /**
   * On Init
   */
  ngOnInit() {
    this.hpid = this.participants.length > 0 ? this.participants[0].eid : 0

    /**
     * Initialize some of the values we see on the screen
     */
    if (this.statsArr !== undefined) {
      this.notstarted = this.statsArr[0] || 0
      this.started = (this.statsArr[1] || 0) + (this.statsArr[2] || 0)
      this.completed = this.statsArr[3] || 0
      this.unverified = this.statsArr[4] || 0
    }

    this.filterProperties = [
      {
        propertyName: 'Verified'
      },
      {
        propertyName: 'Unverified'
      },
    ];

    this.sortProperties = [
      {
        propertyName: 'First Name',
        sortFunction: (p: IInteractParticipant) => p.first_name
      }, {
        propertyName: 'Last Name',
        sortFunction: (p: IInteractParticipant) => p.last_name
      }, {
        propertyName: 'Office',
        sortFunction: (p: IInteractParticipant) => p.office
      }, {
        propertyName: 'Group',
        sortFunction: (p: IInteractParticipant) => p.group_name
      }
    ]

    const source = interval(3000);
    this.getQuestionnaireS(this.activeQuestId)
    this.m_subscription = source.subscribe(val => this.getQuestionnaireS(this.activeQuestId));

    /**
     * Register on the add event (used for adding a new participant)
     */
    this.requestToAddSub = this.requestToAdd.subscribe(() => {
      // here we emiting to parent component to inform him that we enabled filter
      //this.filterApplied.emit({status: true, filter: 'Verified'});
      console.log('received a request to add ...')
      this.filteredParticipants.unshift({
        pid: -2,
        eid: -1,
        first_name: null,
        last_name: null,
        group: null,
        group_name: null,
        group_id: null,
        gender: Gender.unspecified,
        active: false,
        status: InteractParticipantStatusString.notstarted
      })
      this.editrid = -2
      this.createFormGroup(this.editrid)
    })

    /**
     * Get the participants list
     */
    if (this.participants.length === 0) {
      this.actions.fetchParticipants(this.activeQuestId, 0)
    }

    /**
     * Create a form for uploading files
     */
    this.uploadForm = this.fb.group({
      'employeesExcel': null,
      'qid': [this.activeQuestId, Validators.required],
      'qpid': null
    })
  }

  ngOnDestroy() {
    if (this.requestToAddSub !== undefined) { this.requestToAddSub.unsubscribe() }
    if (this.m_subscription !== undefined) { this.m_subscription.unsubscribe() }
  }

  getSelectedGroup = (event: string) => {
    this.groupsTreeState$.subscribe(state => {
      const selectedGroup: any = Object.values(state.groups).find((item: any) => item.name === event);
      const selectedGid = selectedGroup ? selectedGroup.gid.toString() : null;
      const selectedGname = selectedGroup ? selectedGroup.name : null;
      this.ep.patchValue({ group_id: selectedGid })
      this.ep.patchValue({ group_name: selectedGname })
    })
  }
  getQuestionnaireS(qid) {
    this.actions.getQuestionnaireStatus(qid)
    if (this.statsArr !== undefined) {
      this.notstarted = this.statsArr[0] || 0
      this.started = (this.statsArr[1] || 0) + (this.statsArr[2] || 0)
      this.completed = this.statsArr[3] || 0
      this.unverified = this.statsArr[4] || 0
    }
  }

  mouseIsOver(qid) {
    this.hpid = qid
  }

  rowEdit(p) {
    this.createFormGroup(p.eid)
    this.editrid = p.eid
  }

  createFormGroup(rid) {
    const par: IInteractParticipant = _.find(this.participants, p => p.eid === rid)
    const eid = (par === undefined ? -1 : par.eid)

    this.ep = this.fb.group({
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'email': ['', Validators.required],
      'rank': ['', Validators.required],
      'role': ['', Validators.required],
      'job_title': ['', Validators.required],
      'office': ['', Validators.required],
      'group_name': ['', Validators.required],
      'group_id': ['', Validators.required],
      'gender': ['', Validators.required],
      'img_url': ['', Validators.required],
      'phone_number': ['', Validators.required],
      'id': [eid]
    })

    const event = par.group_name ? par.group_name : "Supplier2"
    this.groupsTreeState$.subscribe(state => {
      const selectedGroup: any = Object.values(state.groups).find((item: any) => item.name === event);
      const selectedGid = selectedGroup ? selectedGroup.gid.toString() : null;
      const selectedGname = selectedGroup ? selectedGroup.name : null;
      this.ep.patchValue({ group_id: selectedGid })
      this.ep.patchValue({ group_name: selectedGname })
    })
  }

  saveClicked(ep) {
    const par = ep.value
    par.questionnaire_id = this.activeQuestId
    if (par.id === -1) {
      this.appActions.spinnerOn()
      this.actions.newParticipant(par, this.activeQuestId)
    } else {
      this.appActions.spinnerOn()
      this.actions.updateParticipant(par, this.activeQuestId)
    }
    this.editrid = -1
  }

  rowDeleted(p) {
    this.appActions.spinnerOn()
    this.actions.deleteParticipant(p.pid, this.activeQuestId)
  }

  resetClicked(qpid) {
    this.appActions.spinnerOn()
    this.actions.resetParticipant(qpid, this.activeQuestId)
  }

  resendClicked(p) {
    this.appActions.spinnerOn()
    this.actions.resendParticipant(p.pid, this.activeQuestId)
  }

  participantStatusClicked(p) {
    console.log('participantStatusClicked() - p: ', p)
    this.actions.fetchParticipantStatus(p.pid, this.activeQuestId)
  }

  participantStatusClose() {
    this.actions.closeParticipantsStatusDialog()
  }

  setActiveQuestion(qqid: number, qpid: number) {
    this.actions.setActiveQuestionnaireQuestion(qqid, qpid, this.activeQuestId)
  }

  closeQuestionnaireOfParticipant(qpid: number) {
    this.actions.closeQuestionnaireOfParticipant(qpid, this.activeQuestId)
  }

  activeToggled(p) {
    p.active = !p.active
    p.questionnaire_id = this.activeQuestId
    this.actions.updateParticipant(p, this.activeQuestId)
  }

  getIsActiveId(id) {
    return `isActive-${id}`
  }

  addNewParticipant = () => {
  }

  getStatusColor(status: InteractParticipantStatus | string) {
    switch (status) {
      case InteractParticipantStatus.notstarted:
      case 'Not started':
        return '#be0f3a'

      case InteractParticipantStatus.entered:
      case 'Opened':
      case InteractParticipantStatus.in_process:
      case 'Incomplete':
        return '#f7931e'
      case InteractParticipantStatus.completed:
      case 'Completed':
        return '#0b6460'
      default:
        return '#4e4d4d'
    }
  }

  listWasSorted = (list: IInteractParticipant[]) => {
    // console.log('List was sorted')
    this.filteredParticipants = list
  }

  handleFilteredData = (event: { status: boolean; filterType: string }) => {
    this.filteredParticipants = this.participants.filter(item =>
      event.filterType && event.filterType == 'Verified' ? item.status !== InteractParticipantStatusString.unverified : item.status == InteractParticipantStatusString.unverified
    );
    console.log(event.filterType);
    this.filterApplied.emit({ status: event.status, filter: event.filterType });
  }

  openUploadParticipants = () => {
    this.uploadBoxHeading = 'Upload Participants'
    this.uploadPurpose = 'upload_participants'
    this.uploadboxIsOpen = true
  }

  openUploadUnverifiedEmployees = () => {
    this.uploadBoxHeading = 'Upload Unverified Employees'
    this.uploadPurpose = 'validate_unverified'
    this.uploadboxIsOpen = true
  }

  openUploadAdditionalParticipants = () => {
    this.uploadBoxHeading = 'Upload Additional Participants'
    this.uploadPurpose = 'actions_upload_additional_participants'
    this.uploadboxIsOpen = true
  }

  openUploadPhotos = () => {
    this.uploadBoxHeading = 'Upload Participant Photos'
    this.uploadPurpose = 'img_upload'
    this.uploadboxIsOpen = true
  }

  removeParticipants = () => {
    // let clearParObservable = Observable<any> = this.actions.clearParticipants(this.activeQuestId)
    //this.modalMsg = 'Clear participants...'
    // this.waitForResponse = true
    if (confirm("Are you sure you want to remove participants?")) {
      this.actions.removeParticipants(this.activeQuestId)
    }
    //this.actions.clearParticipants(this.activeQuestId).subscribe(
    //res => this.modalMsg = res)
    // clearParObservable.subscribe(
    //   console.log('hjhjhjhjhjhjhjhjhjhjhjh'))
  }

  participantImage = (image) => {
    if (image === null || image === undefined) {
      return '/assets/images/missing.jpg'
    }
    return image
  }

  /**
   * Use FormData to upload the excel file in binary format.
   */
  onSubmitUpload = (a) => {
    const fd = new FormData()
    fd.append('qid', this.activeQuestId.toString())

    if (this.uploadPurpose === 'upload_participants') {
      fd.append('fileToUpload', this.uploadForm.get('employeesExcel').value)
      this.actions.uploadParticipants(fd)
    } else if (this.uploadPurpose === 'img_upload') {
      this.uploadPhotos(fd)
    } else if (this.uploadPurpose === 'validate_unverified') {
      fd.append('fileToUpload', this.uploadForm.get('employeesExcel').value)
      this.actions.uploadEmployeeToVerify(fd)
    } else if (this.uploadPurpose === 'actions_upload_additional_participants') {
      fd.append('fileToUpload', this.uploadForm.get('employeesExcel').value)
      this.actions.uploadAdditionalParticipants(fd)
    } else {
      throw new Error('Unknown upload purpose')
    }

    this.uploadPurpose = null
    this.uploadboxIsOpen = false
  }

  /**
   * Grab the file into the formControl as soon as it's selected
   */
  onFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      this.uploadForm.get('employeesExcel').setValue(file)
    }
  }

  /**************** Gender functions ******************/
  genderSelected(event: string) {
    if (event === 'Male') {
      this.ep.patchValue({ gender: 0 })
    } else if (event === 'Female') {
      this.ep.patchValue({ gender: 1 })
    } else {
      this.ep.patchValue({ gender: null })
      // throw new Error(`Illegal gender identifier: ${event}`)
    }
  }

  getGender = (g) => {
    if (g === 0 || g === 'male') { return 'Male' }
    if (g === 1 || g === 'female') { return 'Female' }
    return 'Unspecified'
  }

  getGroupList = (event: string) => {
    event = event ? event : "Supplier2"
    this.allGroupArr = _.pull(this.allGroupArr, event)
    this.allGroupArr.unshift(event)
    return this.allGroupArr;
  }

  getGenderList = (g) => {
    let g1 = 2
    if (g === 'male') {
      g1 = 0
    } else if (g === 'female') {
      g1 = 1
    }
    let list = [0, 1, 2]
    list = _.pull(list, g1)
    list.unshift(g1)
    const ret = _.map(list, e => this.getGender(e))
    trace('gender list: ', ret)
    return ret
  }

  /**************** Role functions ******************/
  rankSelected(event: number) {
    this.ep.patchValue({ rank: event })
  }

  getRanksList = (r: number) => {
    if (r === undefined || r === null) { r = 1 }
    let list = _.range(1, 13)
    list = _.pull(list, r)
    list.unshift(r)
    return list
  }

  /**************** Downloads **********************/
  downloadStatus = () => {
    console.log('In downloadStatus()')
    this.actions.downloadParticipantsStatus(this.activeQuestId)
  }

  downloadEmployees = (status: string) => {
    console.log('In downloadEmployees()')
    this.actions.downloadEmployees(this.activeQuestId, status)
  }

  /**
   * /interact_backoffice/actions_download_sample
   */
  downloadSample = () => {
    this.actions.downloadSample(this.activeQuestId)
  }

  /**************** Upload Photos ******************/

  sendImgToServer = (dataUrl, fileName, fd: FormData, idx, length) => {
    fd.append('file_name', fileName)
    fd.append('img_file', dataUrl)
    this.actions.uploadParticipantPhotos(fd, idx, length)
    console.log(idx)
    console.log(length)
  }

  shrinkImage = (img, fileSize) => {
    const canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0)

    let width = img.width
    let height = img.height

    if (fileSize > 100000) {
      if (width > height) {
        if (width > MAX_WIDTH) {
          height = Math.floor(height * (MAX_WIDTH / width))
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width = Math.floor(width * (MAX_HEIGHT / height))
          height = MAX_HEIGHT
        }
      }
    }
    canvas.width = width
    canvas.height = height
    ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, width, height)

    const dataurl = canvas.toDataURL('image/jpeg')
    return dataurl
  }

  resizingSendingOnLoad = (file, fd: FormData, idx, length) => {
    return (e) => {
      const img = document.createElement('img')
      img.onload = () => {
        const dataurl = this.shrinkImage(img, file.size)
        this.sendImgToServer(dataurl, file, fd, idx, length)
      }
      img.src = e.target.result
    }
  }
  photoUploadFinished = () => {

  }

  uploadPhotos = (fd: FormData) => {
    trace('ParticipantsComponent - In uploadPhotos()')
    const ii = document.getElementById('fileToUploadId')
    const files = (<any>ii).files
    var itemsProcessed = 1
    _.forEach(files, (f: any) => {
      console.log('Working on image: ', f.name)
      if (!this.fileIsValid(f)) { return }
      const reader = new FileReader()
      reader.onload = this.resizingSendingOnLoad(f, fd, itemsProcessed, files.length)
      reader.readAsDataURL(f)
      itemsProcessed++
      if (itemsProcessed === files.length) {
        this.photoUploadFinished();
      }
    })
  }

  /**
   * Make sure the file is indeed an image
   */
  fileIsValid = (file: File) => {
    if (file.type.startsWith('image/')) { return true }
    const msg = `Failed to process file: ${file.name} because it is not an image. Type is: ${file.type}`
    this.actions.processParticipantPhotosFail([msg])
    return false
  }

  searchTriggered = (event) => {
    const searchText = event.target.value
    this.actions.filterParticipants(this.activeQuestId, 0, searchText)
  }

  percentComplete = (answered, numOfQuestions) => {
    return Math.round((answered / numOfQuestions) * 100)
  }

  userMap = (p) => {
    console.log('on user map')
    this.actions.getUserMap(this.activeQuestId, p.eid);
  }

  questCompleted = () => {
    if (this.activeQuest && this.activeQuest.state == InteractQuestState.completed)
      return true
    return false
  }

  resendQuestionnaireToUnansweredParticipant = () => {
    this.actions.resendQuestionnaireToUnansweredParticipant(this.activeQuestId)
  }

  toggleCheckbox(id: any) {
    if (this.selectedParticipants[id]) {
      delete this.selectedParticipants[id]
    } else {
      this.selectedParticipants[id] = true
    }
  }

  toggleSelectAll() {
    for (let p of this.filteredParticipants) {
      this.selectedParticipants[p.eid] = this.selectAllChecked
    }
  }

  handleOptionSelected(eventData: { method: string, name: string }) {
    const { method, name } = eventData
    const selectedUsers = this.getTrueIds(this.selectedParticipants)
    const action = this.listOptions.find(option => option.method === method);

    if (action) {
      this.actions[action.method](selectedUsers).subscribe(
        data => {
          console.log(data)
          if (data.success === true) {
            alert('Sending the personal report was successful')
          } else {
            alert(data.error[0])
          }
        },
        error => {
          console.log(error)
          alert(error)
        }
      )
    } else {
      console.error(`Method '${method}' not found in listOptions.`);
    }
  }

  getTrueIds(data: { [key: string]: boolean }): string[] {
    return Object.keys(data).filter(key => data[key]);
  }

  shouldShowBulkOption() {
    return Object.values(this.selectedParticipants).some(value => value === true)
  }

  filteredParticipantsStatus(status: string) {
    switch (status) {
      case 'all':
        this.filteredParticipants = this.participants
        break
      case 'notstarted':
        this.filteredParticipants = this.participants.filter(participant => participant.status === 'Not started')
        break
      case 'started':
        this.filteredParticipants = this.participants.filter(participant => participant.status === 'Incomplete')
        break
      case 'completed':
        this.filteredParticipants = this.participants.filter(participant => participant.status === 'Completed')
        break
      case 'unverified':
        this.filteredParticipants = this.participants.filter(participant => participant.status === 'Unverified')
        break
      default:
        this.filteredParticipants = []
        break
    }
  }

  openUserReport(p: IInteractParticipant) {
    window.open(p.report_url, '_blank');
  }

  fetchAllGroups() {
    this.actions.getGroupdata()
  }
}
