import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InteractActions } from '../../interact.actions';
import { IInteractQuestion, IQuest } from '../../../../app.state';

@Component({
  selector: 'app-selection-question-modal',
  templateUrl: './selection-question-modal.component.html',
  styleUrls: ['./selection-question-modal.component.scss']
})
export class SelectionQuestionModalComponent implements OnInit {
  params: string[] = ['param_a', 'param_b', 'param_c', 'param_d', 'param_e', 'param_f', 'param_g', 'param_h', 'param_i', 'param_j'];
  formData: { [key: string]: any } = {};
  paramNames: { [option: number]: string } = {};
  selectionForm: FormGroup;
  @Input() isOpen: boolean = false;
  @Input() question: IInteractQuestion = null;
  @Output() close = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder, private actions: InteractActions) {
    this.selectionForm = this.formBuilder.group({
      save_param_to: [0, Validators.required]
    });

    this.params.forEach(param => {
      this.selectionForm.addControl(param, this.formBuilder.control('', Validators.required));
    });

    this.params.forEach(param => {
      this.paramNames[param] = param;
    });
  }

  ngOnInit() {
    this.initializeParamNames()
  }

  ngOnChanges() {
    this.initializeParamNames();
  }

  initializeParamNames() {
    this.params.forEach((param, index) => {
      this.paramNames[param] = index;
    });

    if (this.question && this.question.save_param_to !== null && this.question.save_param_to !== undefined) {
      this.formData.save_param_to = this.question.save_param_to;
    } else {
      this.formData.save_param_to = 0;
    }

    if (this.question && this.question.selection_question_options && this.question.selection_question_options.length > 0) {
      const tmpData = {...this.question, selection_question_options: this.question.selection_question_options.filter((val)=>val.name)}
      tmpData.selection_question_options.forEach((param, index) => {
        if(param.name){
          this.formData[this.params[index]] = param.name;
        }
      });
    }
  }

  closeModal() {
    this.isOpen = false;
    this.selectionForm.reset();
    this.formData = {};
    this.formData.save_param_to = 0;
    this.close.emit();
  }

  saveSelectionOptions() {
    if (this.question) {
      if (!this.question.selection_question_options) {
        this.question.selection_question_options = [];
      }

      const relevantKeys = Object.keys(this.formData).filter(key => this.params.includes(key));

      if (this.question.selection_question_options.length > 0) {
        this.question.selection_question_options = relevantKeys.map((param, index) => ({
          id: this.question.selection_question_options[index] ? this.question.selection_question_options[index].id : undefined,
          name: this.formData[param] || ''
        }));
      } else {
        this.question.selection_question_options = relevantKeys.map((param, index) => ({
          name: this.formData[param] || ''
        }));
      }

      this.question.is_selection_question = true;
      this.question.save_param_to = this.formData.save_param_to;

      const tmpData = {...this.question, selection_question_options: this.question.selection_question_options.filter((val)=>val.name)}
      console.log(tmpData);
      this.actions.updateQuestion(tmpData, this.question.questionnaire_id)
      this.isOpen = false;
      this.selectionForm.reset();
      this.formData = {};
      this.formData.save_param_to = 0;
      this.close.emit();
    }
  }

  disableSelectionOptions() {
    if (this.question) {
      if (this.question.is_selection_question == null) {
        this.question.is_selection_question = false;
      } else {
        this.question.is_selection_question = !this.question.is_selection_question;
      }
      this.question.selection_question_options = [];
      this.actions.updateQuestion(this.question, this.question.questionnaire_id)
      this.isOpen = false;
      this.selectionForm.reset();
      this.formData = {};
      this.formData.save_param_to = 0;
      console.log(this.question);
      this.close.emit();
    }
  }
}
