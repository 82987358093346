import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IQuest } from '../../../app.state';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-report-editor',
  templateUrl: './report-editor.component.html',
  styleUrls: ['./report-editor.component.scss']
})
export class ReportEditorComponent implements OnInit {
  @Input() questionnaires: IQuest[];
  @Input() activeQuestId: number;
  @Input() activeQuest: IQuest;
  @Output() reportIntroText: EventEmitter<{ mycontent: string, emailSubject: string, emailBody: string }> = new EventEmitter();
  myForm: FormGroup;
  constructor(private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      mycontent: ['', Validators.required],
      emailSubject: ['', Validators.required],
      emailBody: ['', Validators.required]
    });

    if (typeof this.activeQuest === 'undefined') return;

    const { personal_report_intro, personal_report_email_subject, personal_report_email_body } = this.activeQuest;

    this.myForm.patchValue({
      mycontent: personal_report_intro || '',
      emailSubject: personal_report_email_subject || '',
      emailBody: personal_report_email_body || ''
    });
  }

  sendData() {
    if (this.myForm.valid) {
      const dataToSend = {
        mycontent: this.myForm.value.mycontent,
        emailSubject: this.myForm.value.emailSubject,
        emailBody: this.myForm.value.emailBody
      };
      console.log(dataToSend);
      this.reportIntroText.emit(dataToSend);
    }
  }
}
