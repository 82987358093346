import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IAlertsState, IGroup, IInteractParticipant, InteractTab, IQuest} from '../../app.state';
import {Subscription} from 'rxjs/Subscription';
import {NgRedux, select} from '@angular-redux/store';
import {AjaxService} from '../../services/ajax.service';
import {InteractActions} from '../interact/interact.actions';
import {AppActions} from '../../app.actions';
import {DataService} from '../../services/data.service';
import {ActivatedRoute} from '@angular/router';
import {MapActions} from '../map/map.actions';
import {NavActions} from '../nav/nav.actions';
import {Observable} from 'rxjs/Observable';
import * as util from '../../services/util.service'

@Component({
  selector: 'app-basic-map',
  templateUrl: './basic-map.component.html',
  styleUrls: ['./basic-map.component.scss']
})
export class BasicMapComponent implements OnInit, AfterViewInit {
  groups$: Observable<any>;
  groups: IGroup[]
  questions: any
  questionnaires: IQuest[]
  activeQuest: IQuest
  activeQuestId: number
  activeQuestStats: number[]
  userEid: number = 0
  activeQuestionId: number

  participants: IInteractParticipant[]
  participantsSub: Subscription
  activeQuestIdSub: Subscription

  @select(['interact', 'activeQuestId']) activeQuestId$: Observable<number>
  @select(['interact', 'participants']) readonly participants$: Observable<IInteractParticipant[]>
  @select(['interact', 'openedTab']) readonly selectedTab$: Observable<InteractTab>
  @select(['interact', 'selectedUserMap']) readonly selectedUserMap$: Observable<number[]>
  @select(['interact', 'quests']) readonly quests$: Observable<IQuest[]>
  @select(['interact', 'activeQuestionId']) readonly activeQuestionId$: Observable<number>
  @select(['interact', 'hideNav']) readonly hideNav$: Observable<boolean>
  @select(['mapReducer', 'initialMap']) res$: Observable<any>;


  private userMapMode: boolean;
  private selectedUser: any;
  public userToken: string;

  constructor(
    private ajaxService: AjaxService,
    private actions: InteractActions,
    private appActions: AppActions,
    private ngRedux: NgRedux<IAlertsState>,
    private ds: DataService,
    private route: ActivatedRoute,
    public mapActions: MapActions,
    private navActions: NavActions,
) {
    this.activeQuestId = Number(localStorage.getItem('qqid'));
  }

  ngOnInit() {
    this.groups$ = this.ngRedux.select(state => state.interactMap.groups)


    this.groups$.subscribe(groups => {
      this.groups = groups;
      this.groups = groups.map(group => ({
        ...group,
        color: util.hexaColor(group.color_id)
      }))
    })
  }

  getPartStatus = () => {
    console.log(this.activeQuestId, this.activeQuestionId)
    this.actions.getUserMap(this.activeQuestId, 0);
    this.toggleUserMap();
    setTimeout(() => {
      this.mapActions.handleClickForUserMap('interactMap', this.activeQuestId);
      this.userMapMode = true;
      this.selectedUser = 1;

    }, 1000);
  }

  ngAfterViewInit(): void {
    this.getPartStatus();
  }

  toggleUserMap = () => {

    const mapRef = document.getElementById('mapRef')
    mapRef.style.display = 'flex'
    mapRef.style.alignItems = 'center'
    mapRef.style.justifyContent = 'center'
    mapRef.style.width = '100%'
    mapRef.style.height = '376px'
    mapRef.style.border = '0'
    mapRef.style.zIndex = '99'


    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      },
      50)
  }
}
