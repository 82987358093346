import {
  Component,
  OnInit,
  Input,
  Output
} from '@angular/core'
import { select, NgRedux } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'

import * as _ from 'lodash'

import * as util from '../../services/util.service'
import { getActiveGroupsNoneIsAll } from '../../services/groups.service'
import * as ms from '../../services/map.service'
import { normalizeLinks } from '../../services/graph.service'
import { INode } from './combine.service'
import * as csa from './combine.service.adapter'

import {
  IMapData,
  IMapState,
  IQuest,
  IAppState
} from '../../app.state'

import * as Consts from '../../constants'
import { MapActions } from './map.actions'
import { IMapHandler } from '../../types/map-handler'

import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { Router } from '@angular/router';
import { AjaxService } from 'app/services/ajax.service';

const trace = util.traceToggle(false)

/**
 * A data structure that represents the entire map
 */
@Component({
  selector: 'sa-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, IMapHandler {

  @Input() mapRootId: string
  @Input() activeQuestId: number
  @Input() activeQuestionId: number
  @Input() questionnaires: IQuest[]
  @Input() ma: MapActions
  @Input() isUserMap: Boolean = false;
  @Input() mapID: number;

  selectedUser: number
  userMapMode = false

  @Input() set selectedUserMap(selected: number) {
    if (selected === undefined) { return }
    this.selectedUser = selected
    this.userMapMode = true
  }

  mapData: IMapData[]

  chart: KeyLines.Chart
  waitForChart = true

  contWidth: number
  contHeight: number

  showMapReset = false

  map$: Observable<IMapState>
  mapSubscription: Subscription

  department$: Observable<string>
  questionnaireName$: Observable<string>
  questionTitle$: Observable<string>
  colorBy$: Observable<string>
  filtersStr$: Observable<string>
  dynamicClass = 'initial-class';
  @select(['groups', 'groups']) readonly selectedGroups$: Observable<any>
  activeGids: number[] = null
  groupsSubscription: Subscription

  @select(['interact', 'hideNav']) readonly hideNav$: Observable<boolean>

  @select(['global', 'showChart']) readonly showChart$: Observable<boolean>

  makeBigTitle = 'Full view'
  /** Here we remember the settings before going to full screen in order to be ablet to revert */
  mapRefPosition: string
  mapRefTop: string
  mapRefLeft: string
  mapRefWidth: string
  mapRefHeight: string
  mapRefBorder: string
  mapRefZIndex: string
  isRenderImage: any = false;
  colorsList: any;
  /**
   * Constructor
   */
  constructor(private ngRedux: NgRedux<IAppState>, private router: Router, public ajaxsrv: AjaxService) { }

  ngOnInit() {
    this.getColors();
    this.map$ = this.ngRedux.select([this.mapRootId])
    this.department$ = this.ngRedux.select([this.mapRootId, 'department'])
    this.questionnaireName$ = this.ngRedux.select([this.mapRootId, 'questionnaireName'])
    this.questionTitle$ = this.ngRedux.select([this.mapRootId, 'questionTitle'])
    this.colorBy$ = this.ngRedux.select([this.mapRootId, 'colorBy'])
    this.filtersStr$ = this.ngRedux.select([this.mapRootId, 'filtersStr'])

    /** When data changes - redraw Keylines */
    this.mapSubscription = this.map$.subscribe((map: IMapState) => {
      if (map === undefined) { return }
      if (map && map.nodes.length > 0 && map.colorBy == "Structure") {
        map.nodes.forEach(node => {
          if (this.colorsList && this.colorsList.length > 0) {
            let findIndex = this.colorsList.findIndex(item => item[0] == node.color_id);
            if (findIndex !== -1) {
              node.c = "#" + this.colorsList[findIndex][1];
            }
          }
        });
      }
      this.remap(map)
    })

    /**
     * Listen to groups selection
     * Also, because this subscription is triggered when groups are added then
     * this part of the code is in charge of triggering fetchMapData which
     * loads the map
     */
    this.groupsSubscription = this.selectedGroups$.subscribe((groups) => {

      // This piece of code should be moved to interact.component.ts .
      // For now we handle it from here.
      if (this.mapRootId !== Consts.EXPLORE_INTERACT) { return }
      const gids = getActiveGroupsNoneIsAll(groups)
      if (!_.isEqual(this.activeGids, gids) && gids.length > 0) {
        // Commented by kiran 11-07-2024 - duplicate call
        this.ma.fetchMapData(this.mapRootId, this.activeQuestionId, gids, this.selectedUser)
        this.activeGids = gids
      }
    })

    var currentDiv = document.querySelector(".map-container");
    if (currentDiv) {
      currentDiv.classList.add("new-class" + this.mapID);
    }

    this.ajaxsrv.isUpdateColor.subscribe(res => {
      if (res == true) {
        this.groupsSubscription = this.selectedGroups$.subscribe((groups) => {
          this.activeGids =[]
          if (this.mapRootId !== Consts.EXPLORE_INTERACT) { return }
          const gids = getActiveGroupsNoneIsAll(groups)
          if (!_.isEqual(this.activeGids, gids) && gids.length > 0) {
            this.ma.fetchMapData(this.mapRootId, this.activeQuestionId, gids, this.selectedUser)
            this.activeGids = gids
          }
        })
      }
    });
  }

  getColors() {
    this.ajaxsrv.get('/v3/get_unassigned_group_colors', {}, (res) => {
      const gropColors = JSON.parse(res['_body'])
      this.colorsList = gropColors.colors;
    })
  }

  ngOnDestroy() {
    this.mapSubscription.unsubscribe()
  }

  exportCanvas() {
    html2canvas(document.querySelector('#kl > canvas')).then(originalCanvas => {
      let croppedCanvas = document.createElement('canvas');
      let ctx = croppedCanvas.getContext('2d');

      const leftCrop = 80; // 60
      const bottomCrop = 20; // 30
      croppedCanvas.width = originalCanvas.width - leftCrop;
      croppedCanvas.height = originalCanvas.height - bottomCrop;

      ctx.drawImage(originalCanvas, leftCrop, 0, croppedCanvas.width, croppedCanvas.height, 0, 0, croppedCanvas.width, croppedCanvas.height);

      const contentDataURL = croppedCanvas.toDataURL('image/png');

      let pdf = new jsPDF('p', 'mm', 'a4');
      var width = pdf.internal.pageSize.getWidth();
      var height = (croppedCanvas.height * width) / croppedCanvas.width;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height);
      pdf.save('map.pdf');
    });
  }


  private remap = (map: IMapState) => {
    if (map.nodes === undefined) { return }

    /**
     * 12.02.24 Here, we need to hide all nodes without any link
     *  Filter nodes to only those with an id present in the linkIds
     */

    // const linkIds = new Set(map.links.map(link => [link.id1, link.id2])
    //   .reduce((acc, val) => acc.concat(val), []));
    //
    // map.nodes = map.nodes.filter(node => linkIds.has(node.id));

    /**
     * The difference is whether we want to redraw the entire map because some nodes
     * were added or removed. Or whether we just want to change node properties, like
     * colors for example.
     */
    if (map.redraw) {
      /** Normalise link widths to a 1-10 scale */
      map.links = normalizeLinks(map.links)

      this.mapData = map.nodes.concat(map.links)
      this.loadChart(this.mapData)

      this.ma.mapRedrawIsDone(this.mapRootId)
    } else {
      if (this.chart === undefined) { return }
      const newNodes: any[] = _.map(map.nodes, (n: IMapData) => ({ id: n.id, id1: n.id1, id2: n.id2, c: n.c, ha0: n.ha0 }))
      const newLinks: any[] = _.map(map.links, (l: IMapData) => ({ id: l.id, c: l.c }))
      const changes = _.concat(newNodes, newLinks)
      this.chart.setProperties(changes)
    }
  }

  /**
   * Load the keylines chart
   */
  loadChart(mapData: IMapData[]) {
    trace('Trying to loadChart()')
    if (this.chart === undefined) { return }

    const currentUrl = this.router.url;
    const isMapPage = currentUrl.includes('personal-map') || currentUrl.includes('basic-map');

    const options: KeyLines.LayoutOptions = {
      animate: !isMapPage,
      fixed: this.chart.selection(),
      tidy: true,
      fit: true,
      consistent: true
    }

    const chartData: KeyLines.ChartData = {
      type: 'LinkChart',
      items: <any>mapData
    }

    this.chart.load(chartData)
    this.chart.layout('standard', options)
    setTimeout(async () => {
      if (!this.waitForChart && this.mapID == 2 && !this.isRenderImage) {
        this.getImage();
      }
    }, 4000);
  }

  /**
   * The Keylines callback here. Chart plotting happens here
   */
  klChartReady(chart: KeyLines.Chart) {


    trace('InterfacesComp - klChartReady() - keylines chart is ready')
    this.chart = chart;

    if (this.waitForChart) {
      trace('InterfacesComp - klChartReady() - loading chart')
      this.loadChart(this.mapData)
      this.waitForChart = false
    }
  }

  /**
   * Will receive events for the chart
   */
  klChartEvents(event: any) {
    if (event.name === 'dblclick') {
      this.handleDbClick(event)
      event.preventDefault = true
    }

    if (event.name === 'click') { ms.removeContextMenu() }

    if (event.name === 'contextmenu') {
      this.contextMenuClicked(event)
    }

    if (event.name === 'hover') {
      // console.log('Chart hover event triggered: ', event);
    } else if (event.name === 'delete') {
      // console.log('Chart delete event triggered: ', event);
      event.preventDefault = true
    }
  }

  // ========================== Events handling =============================== //
  private idFromEvent = (e): (number | string) => {
    return e.args[0]
  }

  private handleDbClick = (event) => {
    trace('DbClick() - ', event)
    const nid = this.idFromEvent(event)
    this.ma.handleDbClick(this.mapRootId, nid)
  }

  resetMap = () => {
    if (this.selectedUser) {
      this.userMapMode = false
      this.selectedUser = undefined
      this.ma.fetchMapData(this.mapRootId, this.activeQuestionId, this.activeGids)
    }
    else {
      this.ma.resetMap(this.mapRootId)
    }
  }

  /**
   * Toggle between full screen size and regular map size
   */
  toggleMakeBig = () => {

    const mapRef = document.getElementById('mapRef')
    if (mapRef.style.position === 'fixed') {
      this.makeBigTitle = 'Full view'
      mapRef.style.position = this.mapRefPosition
      mapRef.style.top = this.mapRefTop
      mapRef.style.left = this.mapRefLeft
      mapRef.style.width = this.mapRefWidth
      mapRef.style.height = this.mapRefHeight
      mapRef.style.border = this.mapRefBorder
      mapRef.style.zIndex = this.mapRefZIndex

    } else {

      this.makeBigTitle = 'Partial view'

      const style = window.getComputedStyle(mapRef)
      this.mapRefPosition = style.getPropertyValue('position')
      this.mapRefTop = style.getPropertyValue('top')
      this.mapRefLeft = style.getPropertyValue('left')
      this.mapRefWidth = style.getPropertyValue('width')
      this.mapRefHeight = style.getPropertyValue('height')
      this.mapRefBorder = style.getPropertyValue('border')
      this.mapRefZIndex = style.getPropertyValue('z-index')
      mapRef.style.position = 'fixed'
      mapRef.style.top = '100px'
      mapRef.style.left = '155px'
      mapRef.style.width = '45%'
      mapRef.style.height = '55%'
      mapRef.style.border = '1px solid grey'
      mapRef.style.zIndex = '99'
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    },
      50)
  }

  private contextMenuClicked = (event: any) => {
    const hash = window.location.hash.substring(1);
    const nid = event.args[0]
    if (nid === undefined || nid === null) { return }
    const inode: INode = this.ma.getCombineService().nodeFromId(nid)
    const mapDataNode: IMapData = csa.csToKlNode(inode)
    const modifiedEvent = { ...event };
    if (hash.includes('/basic-map') || hash.includes('/personal-map')) {
      const args = [...modifiedEvent.args];
      args[2] = 500;
      modifiedEvent.args = args;
    }
    ms.handleContextMenuWithNode(modifiedEvent, mapDataNode, this, { clickToIsolate: true })
  }

  clickToIsolate = (nid: number): void => {
    this.ma.handleClickToIsolate(this.mapRootId, nid)
  }

  clickToExport = (nid: number): void => {

    this.toggleMakeBig();

    this.clickForUserMap(nid)

    setTimeout(() => {
      this.exportCanvas();
    }, 1200);

    this.toggleMakeBig();
  }

  clickForUserMap = (nid: number, exportToPdf: boolean = false): void => {
    if (this.userMapMode && this.selectedUser == nid) {
      this.ma.handleClickForUserMap(this.mapRootId, this.activeQuestionId)
      this.userMapMode = false
      this.selectedUser = undefined
    }
    else if (this.selectedUser != nid) {
      this.ma.handleClickForUserMap(this.mapRootId, this.activeQuestionId, nid)
      this.userMapMode = true
      this.selectedUser = nid
    }
  }

  getImage() {
    if (this.mapID == 2) {
      let selecter = ".kl" + this.mapID + " > canvas";

      this.isRenderImage = true;
      setTimeout(() => {
        html2canvas(document.querySelector(selecter)).then(originalCanvas => {
          let croppedCanvas = document.createElement('canvas');
          let ctx = croppedCanvas.getContext('2d');

          const leftCrop = 0;
          const bottomCrop = 0;
          croppedCanvas.width = originalCanvas.width - leftCrop;
          croppedCanvas.height = originalCanvas.height - bottomCrop;

          ctx.drawImage(originalCanvas, leftCrop, 0, croppedCanvas.width, croppedCanvas.height, 0, 0, croppedCanvas.width, croppedCanvas.height);
          const contentDataURL = croppedCanvas.toDataURL('image/png');

          const img = document.createElement('img');
          img.src = contentDataURL;
          img.style.width = '100%';

          const container = document.querySelector('.new-class' + this.mapID);

          if (container) {
            let existingImage = container.querySelector('img');
            if (!existingImage) {
              container.appendChild(img);
            }
          }

          const canvas = document.querySelector(selecter) as HTMLCanvasElement;
          setTimeout(() => {
            //console.log("hide canvas");

            // canvas.style.display = 'none';
          }, 1000);
        });
      }, 2000);
    }
  }

}
