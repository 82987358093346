import * as _ from 'lodash'

import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core'

export interface ISortedProp {
  propertyName: string,
  sortFunction: (sortlist: any) => any
}

@Component({
  selector: 'sa-sort-buttons',
  templateUrl: './sort-buttons.component.html',
  styleUrls: ['./sort-buttons.component.scss']
})
export class SortButtonsComponent {
  @Input() sortItems: {propertyName: string, sortFunction: (sortlist: any) => any }[]
  @Input() listToSort: any[]

  @Output() listSorted: EventEmitter<any[]>

  sortPropertyInx: string = null

  constructor() {
    this.listSorted = new EventEmitter()
  }

  sortClicked = (prop: ISortedProp) => {
    if (prop.propertyName === 'Group' || prop.propertyName === 'Office') {
      console.log('Pre-sorting list by last name for Group or Office sorting');
      this.listToSort = _.sortBy(this.listToSort, item => item.last_name);
    }

    const list = _.sortBy(this.listToSort, prop.sortFunction);
    this.listSorted.emit(list);
    this.sortPropertyInx = prop.propertyName;
  }

}
