﻿import {Component, HostListener, OnInit, ReflectiveInjector} from '@angular/core'
import {Router, NavigationEnd, NavigationStart} from '@angular/router'
import { NgRedux, select } from '@angular-redux/store'
import { Observable } from 'rxjs/Observable'

import { UserModel } from './components/user/user.model'

import { AjaxService } from './services/ajax.service'
import { LogoutService } from './services/logout.service'
import { IGlobalState } from './app.state'
import { AppActions } from './app.actions'

import * as util from './services/util.service'
import {filter} from 'rxjs/operators';

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @select(['global', 'navOpened']) readonly navOpened$: Observable<boolean>
  @select(['global', 'currentTab']) readonly currentTab$: Observable<string>
  @select(['global', 'currentUser']) readonly currentUser$: Observable<string>

  public isPersonalMapRoute: boolean = false;
  public isBasicMapRoute: boolean = false;

  constructor(
    public ajaxsrv: AjaxService,
    private router: Router,
    private appActions: AppActions,
    private ngRedux: NgRedux<IGlobalState>,
    private logoutService: LogoutService,
    ) {
    this.checkUrl();
    const hash = window.location.hash.substring(1);
    const queryParamsIndex = hash.indexOf('?');
    const path = queryParamsIndex > -1 ? hash.substring(0, queryParamsIndex) : hash;

    if (path.includes('personal-map')) {
      this.parseAndStoreTokens()
      // Mock returned data from server
      const fakeUser = {email: 'someone@email.com', first_name: 'someone', last_name: '', user_type: 'admin',
        reports_encryption_key: 'abcdefg', session_timeout: 0, password_update_interval: 0, max_login_attempts: 0
      }

      const userData = new UserModel(fakeUser);

      this.appActions.userSignedInAction(userData);
      this.isPersonalMapRoute = true;
      this.router.navigate(['/personal-map']);

    } else if (path.includes('basic-map')) {
      this.parseAndStoreTokens();
      this.parseAndStoreQidAndCompany();
      const fakeUser = {email: 'someone@email.com', first_name: 'someone', last_name: '', user_type: 'admin',
        reports_encryption_key: 'abcdefg', session_timeout: 0, password_update_interval: 0, max_login_attempts: 0
      }

      const userData = new UserModel(fakeUser);

      this.appActions.userSignedInAction(userData);
      this.isPersonalMapRoute = true;
      this.router.navigate(['/basic-map']);

    } else {
      this.isPersonalMapRoute = false;
    }

      this.currentUser$.subscribe((user: any) => {
        if (user !== undefined && user.email !== undefined) {
          appActions.fetchConfigParams()
        }

        /** For questionnaire customers show only the Interact tab **/
        if (user.product_type === 'questionnaire_only') {
          (<any>window.location) = '/#/interact'
        }
      })
    }

  @HostListener('window:hashchange', ['$event'])
  onHashChange(event: Event) {
    this.checkUrl();
  }
  ngOnInit() {
    this.checkLogin()
  }

  checkLogin(): void {
    if (this.isPersonalMapRoute) {
      return;
    }
    this.ajaxsrv.get('/v3/get_user_details', {}, (res) => {
      const user_info = JSON.parse( res['_body'] )
      console.log(user_info)
      trace('AppComponent - checkLogin() - result from server: ', user_info)
      const userData: UserModel = new UserModel(user_info)
      this.appActions.userSignedIsInAction(userData)
    })
  }

  getGlobalState: () => IGlobalState = function() {
    return this.ngRedux.getState().global
  }

  isLoggedIn() {
    if ( localStorage.getItem('jwtToken') === null ) { return false }
    return true
  }

  isSpinnerOn() {
    const state: IGlobalState = this.getGlobalState()
    return state.spinner
  }

  parseAndStoreTokens() {
    // Assuming the URL is "http://localhost:4200/#/personal-map?token=199620db94a8795fa5af6?auth_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMzcsImV4cCI6MTcxMjM0NDU5Mn0.4oS7MlVK7TSzjMPtJRlDcqebPy7dilpGkwucXM2keXU"
    const url = new URL(window.location.href);
    const hashParts = url.hash.split('?');
    console.log(hashParts)
    // This will hold the combined query parameters from both parts of the URL.
    const queryParams = new URLSearchParams();

    // Loop over the hash parts that contain query parameters and combine them.
    hashParts.slice(1).forEach(part => {
      const params = new URLSearchParams(part);
      params.forEach((value, key) => {
        queryParams.set(key, value);
      });
    });

    // Now, you can get 'token' and 'auth_token' from queryParams and store them in localStorage
    const token = queryParams.get('token');
    const authToken = queryParams.get('auth_token');

    if (token) {
      localStorage.setItem('token', token);
    }

    if (authToken) {
      const jwtToken = JSON.stringify({
        auth_token: authToken,
        user: {
          id: 3,
          email: "danny@spectory.com"
        }
      });
      localStorage.setItem('jwtToken', jwtToken);
    }
  }

  parseAndStoreQidAndCompany(){
    const hash = window.location.hash.substring(1);
    const queryParams = new URLSearchParams(hash.split('?')[1]);
    console.log(hash)
    const qqid = queryParams.get('qqid');
    const company_id = queryParams.get('company_id');

    if (qqid) {
      localStorage.setItem('qqid', qqid);
    }

    if (company_id) {
      localStorage.setItem('company_id', company_id);
    }
  }

  checkUrl() {
    const hash = window.location.hash.substring(1);
    this.isBasicMapRoute = hash.includes('basic-map');
  }
}
