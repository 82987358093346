﻿import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import * as _ from 'lodash'
import {Observable} from 'rxjs/Observable'
import {Subscription} from 'rxjs/Subscription'
import {select} from '@angular-redux/store'

import {InteractActions} from './interact.actions'
import {
  ICompany,
  IFactor,
  IGroup,
  IInteractCollaborationMetricsReportRow,
  IInteractCollaborationReportRow,
  IInteractParticipant,
  IInteractQuestion,
  IInteractState,
  InteractParticipantStatus, InteractParticipantStatusString,
  InteractQuestTab,
  InteractTab,
  IParticipantStatus,
  IQuest
} from '../../app.state'

import {getActiveGroupsNoneIsAll} from '../../services/groups.service'
import * as util from '../../services/util.service'
import {
  interactStateFromUrl,
  isInteractStateInUrlEmpty,
  setInteractStateInUrl,
  updateUrlParams
} from './interact.service'
import {NavActions} from '../nav/nav.actions'
import {MapActions} from '../map/map.actions'
import {clearMapStateInLocalService} from '../map/map-persist.service'
import {UserModel} from '../user/user.model'
import {log} from "util";
import { AjaxService } from 'app/services/ajax.service'
// import { IGlobalState } from '../../app.state'

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-app-int',
  templateUrl: './interact.component.html',
  styleUrls: ['./interact.component.scss']
})
export class InteractComponent implements OnInit, OnDestroy {
  currentTab = ''
  title = 'Explore'
  subtitle = ''
  headingsTitle = 'My Questionnaires'

  questionnaires: IQuest[]
  activeQuest: IQuest
  activeQuestId: number
  activeQuestStats: number[]

  questions: IInteractQuestion[]
  activeQuestionId: number
  participants: IInteractParticipant[]
  factors: IFactor[]
  isAllowedAddQ: boolean
  currentUser: UserModel

  /** Infinite scroll */
  @select(['interact', 'fetchingParticipants']) readonly fetchingParticipants$: Observable<boolean>
  @select(['interact', 'participantsOffset']) readonly participantsOffset$: Observable<number>
  @select(['interact', 'participantStatus']) readonly participantStatus$: Observable<IParticipantStatus>
  @select(['interact', 'openedTab']) readonly selectedTab$: Observable<InteractTab>
  @select(['interact', 'settingsTab']) readonly settingsTab$: Observable<InteractQuestTab>
  @select(['interact', 'activeQuestId']) readonly activeQuestId$: Observable<number>
  @select(['interact', 'activeQuestionId']) readonly activeQuestionId$: Observable<number>
  @select(['interact', 'questIsOpened']) readonly questIsOpened$: Observable<boolean>
  @select(['interact', 'quests']) readonly quests$: Observable<IQuest[]>
  @select(['interact', 'questions']) readonly questions$: Observable<IInteractQuestion[]>
  @select(['interact', 'participants']) readonly participants$: Observable<IInteractParticipant[]>
  @select(['interact', 'errors']) readonly errors$: Observable<string[]>
  @select(['interact', 'uploadInfo']) readonly uploadInfo$: Observable<string[]>
  @select(['interact', 'isLastImage']) readonly isLastImage$: Observable<boolean>
  @select(['interact', 'lengthOfPhotos']) readonly lengthOfPhotos$: Observable<number>

  @select(['interact', 'collaborationResults']) readonly collaborationResults$: Observable<IInteractCollaborationReportRow[]>
  @select(['interact', 'collaborationMetricsResults']) readonly collaborationMetricsResults$: Observable<IInteractCollaborationMetricsReportRow[]>
  @select(['interact', 'activeParams']) readonly activeParams$: Observable<any>
  @select(['interact', 'sliderVal']) readonly sliderVal$: Observable<number>

  @select(['interact', 'collaborationScore']) readonly collaborationScore$: Observable<number>
  @select(['interact', 'synergyScore']) readonly synergyScore$: Observable<number>
  @select(['interact', 'centralityScore']) readonly centralityScore$: Observable<number>
  @select(['interact', 'hideNav']) readonly hideNav$: Observable<boolean>
  @select(['global', 'currentUser', 'user_type']) readonly userRole$: Observable<string>
  @select(['global', 'currentUser', 'is_allowed_create_questionnaire']) readonly isAllowedCreateQ$: Observable<boolean>

  @select(['interact', 'activeQuestStats']) readonly activeQuestStats$: Observable<number[]>
  @select(['interact', 'factors']) readonly factors$: Observable<IFactor[]>

  @select(['groups', 'groups']) readonly groups$: Observable<IGroup[]>
  @select(['global', 'currentUser']) readonly currentUserState$: Observable<UserModel>

  @select(['interact', 'selectedUserMap']) readonly selectedUserMap$: Observable<number[]>
  @select(['interact', 'selectedCompanyId']) readonly selectedCompanyId$: Observable<number>
  @select(['interact', 'companies']) readonly companies$: Observable<ICompany[]>


  selectedGroups: number[]
  companiesList: ICompany[]
  companyName: string

  groupsSub: Subscription
  settingsTabSub: Subscription
  activeQuestIdSub: Subscription
  questsSub: Subscription
  questionsSub: Subscription
  questStatsSub: Subscription
  participantsSub: Subscription
  selectedTabSub: Subscription
  activeQuestionIdSub: Subscription
  questIsOpenedSub: Subscription
  factorsSub: Subscription
  // currentUserSub: Subscription
  isAllowedCreateQSub: Subscription
  selectedCompanyIdSub:Subscription
  companiesSub:Subscription
  @Output() addRequestEmitter: EventEmitter<number>
  public filteredParticipants: IInteractParticipant[];
  private isFiltered: boolean = false;
  private currentFilter: string = 'Verified';

  constructor(private interactActions: InteractActions,
              private navActions: NavActions,
              public mapActions: MapActions,
              private ajaxService :  AjaxService) {
    this.addRequestEmitter = new EventEmitter()
  }

  ngOnDestroy() {
    this.settingsTabSub.unsubscribe()
    this.activeQuestIdSub.unsubscribe()
    this.questsSub.unsubscribe()
    this.questionsSub.unsubscribe()
    this.participantsSub.unsubscribe()
    this.selectedTabSub.unsubscribe()
    this.groupsSub.unsubscribe()
    this.activeQuestionIdSub.unsubscribe()
    this.questIsOpenedSub.unsubscribe()
    this.questStatsSub.unsubscribe()
    this.factorsSub.unsubscribe()

    this.isAllowedCreateQSub.unsubscribe()
  }

  ngOnInit() {

    this.companiesSub = this.companies$.subscribe((companies: ICompany[]) => {
      this.companiesList = companies || [];
    })

    this.selectedCompanyIdSub = this.selectedCompanyId$.subscribe((cid: number) => {
      if(this.companiesList.length > 0) {
        _.each(this.companiesList, (c) => {
          if(c.id == cid)
            this.companyName = `${c.name} > `
        })
      }else {
        this.interactActions.getCompanies()
      }
    });

    this.groupsSub = this.groups$.debounceTime(1000).subscribe((groups: IGroup[]) => {
      this.selectedGroups = getActiveGroupsNoneIsAll(groups)
      updateUrlParams('groups', this.selectedGroups)
    })

    this.settingsTabSub = this.settingsTab$.subscribe( (state: InteractQuestTab) => {
      this.updateTitleFromStateChange(state)
      updateUrlParams('settingsTab', state)
    })

    this.questIsOpenedSub = this.questIsOpened$.subscribe( (state: boolean) => {
      updateUrlParams('questIsOpened', state)
    })

    this.selectedTabSub = this.selectedTab$.subscribe((state: InteractTab) => {
      trace('InteractComponent - subscribe() for selectedTab$, state: ', state)
      switch (state) {
        case InteractTab.collaboration:
          this.title = 'Collaboration'
          break
        case InteractTab.explore:
          this.title = 'Explore'
          break
        case InteractTab.settings:
          this.title = 'Settings'
          break
      }
      updateUrlParams('openedTab', state)
    })

    /**
     * Listen to change in active questionnaire
     */
    this.activeQuestIdSub =  this.activeQuestId$.subscribe((state: number) => {
      if (state === undefined) { return  }
      this.activeQuestId = state
      trace('interactComponent - active quest SUB - got state = ', state)
      updateUrlParams('activeQuestId', state)
      /** Need to clear the map's state here */
      clearMapStateInLocalService()
     // Commented by kiran 11-07-2024 - for multiple time map rendering
     // this.mapActions.resetMap('interactMap')

      trace('interactComponent - Goign to fetch questions, activeQuestId: ', this.activeQuestId)
      this.interactActions.fetchQuestions(state)

      if (this.questionnaires === undefined) {return}

      this.activeQuest =
        _.find(this.questionnaires, (q: IQuest) => {
          return (q.id === state)
        })

      if (this.activeQuest === undefined) {
        this.interactActions.fetchQuestionnaires()
      }
      console.log('Listen to change in active questionnaire activeQuestIdSub')
      // 15.04.24 - commented out because it was causing a bug - duplicated fetch of groups
      if (this.activeQuest !== undefined && this.questionnaires.length > 0) {
        this.activeQuestStats = this.activeQuest.stats
        this.navActions.fetchGroups(this.activeQuest.snapshot_id, this.activeQuest.id)
      }
    })

    this.activeQuestionIdSub = this.activeQuestionId$.subscribe((state: number) => {
      this.activeQuestionId = state
      updateUrlParams('activeQuestionId', state)
    })

    /**
     * QUestionnaires list has changed
     */
    this.questsSub = this.quests$.subscribe((state: IQuest[]) => {
      this.questionnaires = state
      if (this.activeQuestId !== undefined) {
        this.activeQuest =
        _.find(this.questionnaires, (q: IQuest) => {
          return q.id === this.activeQuestId
        })
      }

      if (this.activeQuest !== undefined && this.questionnaires.length > 0) {
        this.ajaxService.isUpdatequestionValue.next(this.activeQuest);
        this.activeQuestStats = this.activeQuest.stats
        this.navActions.fetchGroups(this.activeQuest.snapshot_id, this.activeQuestId)
      }
    })
    /**
     * Questions list has changed
     * Need to make sure that the current question ID exists and belongs to the current
     * questionnaire.
     */
    this.questionsSub = this.questions$.subscribe((state: IInteractQuestion[]) => {
      this.questions = state

      let qqid = -1
      // If there is no active question ID and there are questions
      if (this.activeQuestionId === undefined || this.activeQuestionId === -1) {
        if (this.questions.length > 0) {
          const activeQuestions = _.filter(this.questions, q => q.active)
          qqid = activeQuestions.length > 0 ? activeQuestions[0].id : -1
        }
        this.interactActions.setActiveQuestion(qqid)
        return
      }

      // If there is an active question ID, but it doesn't belong to current questionnaire then
      // we need to change it
      if (this.activeQuestId !== undefined && this.questions.length > 0) {
        const activeQuestion = this.questions.find((qq: IInteractQuestion) => qq.id === this.activeQuestId)
        if (activeQuestion === undefined) {
          this.interactActions.setActiveQuestion(this.questions[0].id)
        }
      }
    })


    this.factorsSub = this.factors$.subscribe((state: IFactor[]) => {
      this.factors = state

    })
    /**
     * Participants list has changed
     */
    this.participantsSub = this.participants$.subscribe((state: IInteractParticipant[]) => {
      console.log(`participant has changes`)
      console.log(this.currentFilter)
      this.participants = state
      this.applyFilter();
      // this.interactActions.fetchFactors(this.activeQuestId)
    })
    this.questStatsSub = this.activeQuestStats$.subscribe((state: number[]) => {
      this.activeQuestStats = state
    })
    // this.currentUserState$.subscribe(state => {
    //   if (state === undefined) { return }
    //   this.currentUser = state
    //   this.isAllowedAddQ = this.currentUser.isAthorizedToCreateQuestionnaire()
    //   console.log("<L<LL<LL")
    //   console.log(this.isAllowedAddQ)
    // })

    this.isAllowedCreateQSub = this.isAllowedCreateQ$.subscribe((state: boolean) => {
      this.isAllowedAddQ = state
    })

    /**
     * The URL (window.location) saves the screens state accross refresh. Here we check if such a
     * state exists in the URL, if not we initialize it. If yes we set the Redux state accordingly.
     */
    trace('interactComponent - calling interactStateFromUrl() from init')
    const currentUrlParams = interactStateFromUrl()
    if ( isInteractStateInUrlEmpty() ) {
      setInteractStateInUrl(currentUrlParams, this.selectedGroups)
    } else {
      this.setInteractStateFromIInteractState(currentUrlParams)
    }

    if (this.questionnaires.length === 0) {
      this.interactActions.fetchQuestionnaires()
    }

  }

  setInteractStateFromIInteractState = (s: IInteractState): void => {
    trace('InteractComponent - setInteractStateFromIInteractState()')
    this.interactActions.setStateFromIInteractState(s)
  }

  errorboxClose = () => {
    this.interactActions.errrBoxClose()
  }
  uploadInfoClose = (qid) => {
    this.interactActions.uploadInfoClose(qid)
  }

  updateTitleFromStateChange = (state: InteractQuestTab) => {
    switch (state) {
      case InteractQuestTab.quest:
        this.headingsTitle = 'Questionnaire'
        break
      case InteractQuestTab.questions:
        this.headingsTitle = 'Questions'
        break
      case InteractQuestTab.participants:
        this.headingsTitle = 'Participants'
        break
      case InteractQuestTab.test:
        this.headingsTitle = 'Verification'
        break
      case InteractQuestTab.reports:
        this.headingsTitle = 'Reports'
        break
      case InteractQuestTab.data_mapping:
        this.headingsTitle = 'Data Mapping'
        break
      default:
        this.headingsTitle = 'My Questionnaires'
        break
    }
  }

  menuItemClicked(m) {
    this.interactActions.menuItemClicked(m)
    updateUrlParams('openedTab', m)
  }

  displayPlus() {
    switch (this.headingsTitle) {
        case 'My Questionnaires':
          if(this.isAllowedAddQ)
            return true
          else
            return false
        case 'Participants':
        case 'Questions':
           return true
        default:
            return false
    }
  }

  addClicked() {
    this.addRequestEmitter.emit()
  }

  questionnaireReport() {
    this.interactActions.downloadQuestionnaireReport(this.activeQuestId)
  }

  bidirectionalReport() {
    this.interactActions.downloadBidirectionalReport(this.activeQuestId)
  }

  scoresReport() {
    this.interactActions.scoresReport(this.activeQuestId)
  }
  networkMetricsReportA() {
    this.interactActions.networkMetricsReportA(this.activeQuestId)
  }

  moreParticipants = (offset: number) => {
    if(this.headingsTitle === 'Participants'){
      this.interactActions.fetchParticipants(this.activeQuest.id, offset )
    }
  }

  toggleNav = () => {
    this.interactActions.mapNavToggle()
  }

  uploadSummaryText(uploadInfo: string[]) {
    let succ = 0
    _.forEach(uploadInfo, (inf) => {
      if(inf.includes('Successfully'))
        succ++
    })
    return `${succ} photos out of ${uploadInfo.length} uploaded succesfully`
  }

  onFilterApplied(event: { status: boolean; filter: string}) {
    this.currentFilter = event.filter;
    this.isFiltered = event.status;
  }

  private applyFilter() {
    switch (this.currentFilter) {
      case 'Verified':
        this.filteredParticipants = this.participants.filter(item =>
          item.status !== InteractParticipantStatusString.unverified);
        break;
      case 'Unverified':
        this.filteredParticipants = this.participants.filter(item =>
          item.status == InteractParticipantStatusString.unverified);
        break;
      default:
        break;
    }
  }

  handleReportIntroText(reportData: {mycontent: string, emailSubject: string, emailBody: string}) {
    this.interactActions.sendReportIntroText(reportData, this.activeQuest.id)
  }
}
