﻿import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgReduxModule} from '@angular-redux/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { SelectboxComponent } from './selectbox/selectbox.component'
import { SortButtonsComponent } from './sort-buttons/sort-buttons.component'
import { DropDownComponent } from './dropdown/dropdown.component'
import { TogglerComponent } from './toggler/toggler.component'
import { NumberPickerComponent } from './number-picker/number-picker.component'
import { OpenCloseWedgeComponent } from './open-close-wedge.component'
import { TableSortingExample } from './table-example/table-example.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatTableModule } from '@angular/material/table'
import { InfScrollDirective } from './infscroll.directive'
import { MatSortModule } from '@angular/material/sort'
import { MatSliderModule } from '@angular/material/slider'
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef }  from '@angular/material/dialog'
import { SliderComponent } from './slider/slider.component'
;
import { DialogElements } from './dialog/dialog.component'
// import { MultiSelectComponent } from './multiselect-dropdown/multiselect.component'
import { NgMultiSelectDropDownModule } from './multiselect-dropdown/ng-multiselect-dropdown.module'
;
import { FilterButtonsComponent } from './filter-buttons/filter-buttons.component'
;
import { BulkOptionComponent } from './bulk-option/bulk-option.component'

import { ReportEditorComponent } from './report-editor/report-editor.component'
@NgModule({
  declarations: [
    SelectboxComponent,
    SortButtonsComponent,
    DropDownComponent,
    InfScrollDirective,
    TogglerComponent,
    NumberPickerComponent,
    OpenCloseWedgeComponent,
    TableSortingExample,
    SliderComponent,
    DialogElements,
    FilterButtonsComponent,
    ReportEditorComponent,
    BulkOptionComponent  ],
  entryComponents: [DialogElements],
  imports: [
    NgReduxModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSliderModule,
    MatSortModule,
    MatDialogModule,
    NgMultiSelectDropDownModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ],
  exports: [
    SelectboxComponent,
    SortButtonsComponent,
    DropDownComponent,
    InfScrollDirective,
    TogglerComponent,
    NumberPickerComponent,
    OpenCloseWedgeComponent,
    TableSortingExample,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    SliderComponent,
    DialogElements,
    NgMultiSelectDropDownModule,
    FilterButtonsComponent,
    ReportEditorComponent,
    BulkOptionComponent

  ]
})

export class WidgetsModule {
  constructor() {}
}
