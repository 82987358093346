import {AfterViewInit, Component, OnInit} from '@angular/core'
import {AjaxService} from '../../services/ajax.service'
import {InteractActions} from '../interact/interact.actions'
import {UserModel} from '../user/user.model'
import {AppActions} from '../../app.actions'
import {NgRedux, select} from '@angular-redux/store'
import {IAlertsState, IInteractParticipant, IInteractQuestion, InteractTab, IQuest} from '../../app.state'
import {DataService} from '../../services/data.service'
import {Observable} from 'rxjs'
import * as _ from 'lodash';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Router} from '@angular/router';
import {MapActions} from '../map/map.actions';
import {updateUrlParams} from '../interact/interact.service';
import {NavActions} from '../nav/nav.actions';

@Component({
  selector: 'app-user-map',
  templateUrl: './user-map.component.html',
  styleUrls: ['./user-map.component.scss']
})
export class UserMapComponent implements OnInit, AfterViewInit {
  questions: any
  questionnaires: IQuest[]
  activeQuest: IQuest
  activeQuestId: number = 503
  activeQuestStats: number[]
  userEid: number = 25753
  activeQuestionId: number = 4562

  participants: IInteractParticipant[]
  participantsSub: Subscription
  activeQuestIdSub: Subscription

  @select(['interact', 'activeQuestId']) activeQuestId$: Observable<number>
  @select(['interact', 'participants']) readonly participants$: Observable<IInteractParticipant[]>
  @select(['interact', 'openedTab']) readonly selectedTab$: Observable<InteractTab>
  @select(['interact', 'selectedUserMap']) readonly selectedUserMap$: Observable<number[]>
  @select(['interact', 'quests']) readonly quests$: Observable<IQuest[]>
  @select(['interact', 'activeQuestionId']) readonly activeQuestionId$: Observable<number>
  @select(['interact', 'hideNav']) readonly hideNav$: Observable<boolean>

  private userMapMode: boolean;
  private selectedUser: any;
  public userToken: string;

  constructor(
    private ajaxService: AjaxService,
    private actions: InteractActions,
    private appActions: AppActions,
    private ngRedux: NgRedux<IAlertsState>,
    private ds: DataService,
    private route: ActivatedRoute,
    public mapActions: MapActions,
    private navActions: NavActions,
  ) {
    this.userToken = localStorage.getItem('token');
  }

  ngOnInit() {

  }

  getPartStatus = () => {
    console.log(this.activeQuestId, this.activeQuestionId)
    this.actions.getUserMap(4508, this.userEid);
    this.toggleUserMap();
    setTimeout(() => {
      this.mapActions.handleClickForPersonalUserMapForPDF('interactMap', this.userToken);
      this.userMapMode = true;
      this.selectedUser = 1;

    }, 500);
  }

  ngAfterViewInit(): void {
    this.getPartStatus();
  }

  toggleUserMap = () => {

    const mapRef = document.getElementById('mapRef')
      mapRef.style.position = 'fixed'
      mapRef.style.top = '0'
      mapRef.style.left = '0'
      mapRef.style.width = '100%'
      mapRef.style.height = '100%'
      mapRef.style.border = '0'
      mapRef.style.zIndex = '99'


    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      },
      50)
    }
}
