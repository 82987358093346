//
//     Angular components KeyLines v3.8.0-3469
//
//     Copyright © 2011-2017 Cambridge Intelligence Limited.
//     All rights reserved.
//

import {
  Component, ElementRef, Input, Output, EventEmitter, Injectable, AfterViewInit, OnDestroy,
  OnChanges, SimpleChange, ContentChildren, HostListener, ViewChild, OnInit
} from '@angular/core'
import { Observable } from 'rxjs/Observable'
import {Router} from '@angular/router';
import html2canvas from 'html2canvas';
import { select, NgRedux } from '@angular-redux/store';


// allow KeyLines global
declare const KeyLines: KeyLines.KeyLines
// Promisify Keylines
KeyLines.promisify(Promise)

@Injectable()
export class KlComponentsService {

  private _klPaths(base: string = '', images: string) {
    const paths: KeyLines.PathsOptions = {
      assets: base + 'assets/',
      images: base // default if not separately defined
    };
    if (images) {
      paths.images = images;
    }
    return paths;
  }

  create(klComponents: KeyLines.Component[], klBasePath: string, klImagesPath: string) {
    // KeyLines paths configuration
    const paths = this._klPaths(klBasePath, klImagesPath)
    KeyLines.paths(paths);

    // KeyLines create components
    return KeyLines.create(klComponents)
  }
}

@Component({
  selector: 'sa-kl-component',
  template: '<div #qqq id="kl" (window:resize)="onResize(true, $event)"><div #container><div></div></div></div>',
  styles: [
    'kl-comp { height: 200px; }'
  ]
})
export class KlComponent implements OnChanges, OnDestroy, OnInit {
  // @Input() style: any; // optional
  @Input() map_id:any;

  @Input() options: KeyLines.ChartOptions = {} // optional

  /** Using this observalbe to notify Keylines that a resizing event should take place */
  @Input() forceResize: Observable<boolean>

  @Input() showChart: boolean
  @Input() mapRootId: string

  // @Input() containerClass = ''; // optional

  // tslint:disable-next-line:no-output-rename
  @Output('klReady') klReady = new EventEmitter() // optional
  // tslint:disable-next-line:no-output-rename
  @Output('klEvents') klEvents = new EventEmitter() // optional

  // Save the reference of the container element: see #container in the template
  @ViewChild('container')
  private containerElement: ElementRef

  // The KeyLines component
  private component: KeyLines.Chart

  @ViewChild('qqq')

  private el: ElementRef
  @select(['global', 'navOpened']) readonly navOpened$: Observable<string>
  constructor(el: ElementRef, private router: Router) {
    // Remove KL id to the parent
    el.nativeElement.removeAttribute('id');
    this.el = el
  }

  ngOnInit() {
    var currentDiv = document.getElementById("kl");
    if(currentDiv){
      currentDiv.classList.add("kl"+this.map_id);
    }

    if (this.navOpened$) {
      this.navOpened$.subscribe((state) => {
        setTimeout(() => {this.onResize(true, null)}, 0)
      });
    }

    if (this.forceResize) {
      this.forceResize.subscribe((state) => {
        setTimeout(() => {this.onResize(true, null)}, 0)
      })
    }
  }

  // lifecycle hooks
  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    // console.log('ngOnChanges()')
    const { options } = changes;
    // Refresh the options when necessary
    if (options && !options.isFirstChange()) {
      this.refreshOptions(options.currentValue);
    }
  }
  ngOnDestroy() {
    // unbind all the events
    this.component.unbind('all', null);
  }

  // Kl instructions
  getHeader(): KeyLines.Component {
    this.options = { overview : { icon : false }, minZoom:0.05 }
    return { element: this.containerElement.nativeElement, options: this.options };
  }

  setUpComponent(component: KeyLines.Chart) {
    // console.log('setUpComponent()')
    // save the reference of the component
    this.component = component;
    const currentUrl = this.router.url
    if (currentUrl.includes('/personal-map') || currentUrl.includes('/basic-map')) {
      this.component.options({ navigation: {shown: false} })
    }
    // resize the component
    this.onResize(false, null);
    // trigger a klReady event with the component reference
    this.klReady.emit(component);
    // bind the component events
    this.registerEvent();
  }

  registerEvent() {
    this.component.bind('all', (name: string, ...args: string[]) => {
      if (name !== 'redraw') {
        // define the event
        const klEvent = { name, args, preventDefault: false }
        // dispatch the event to the parent
        this.klEvents.emit(klEvent)
        // return the preventDefault value
        return klEvent.preventDefault
      }
    })
  }

  refreshOptions(options: KeyLines.ChartOptions) {
    this.component.options(options);
  }

  onResize(doFit: boolean = true, $event) {
    if (this.component) {
      const cont = document.getElementById('mapRef');
      const canvas = cont.querySelector('canvas');
      const h = cont.offsetHeight - 25
      const w = cont.offsetWidth - 10

      KeyLines.setSize(this.containerElement.nativeElement, w, h)
      if (doFit) {
        this.component.zoom('fit')
      }
      if(canvas){
        canvas.style.width = '100%';
      }
    }
  }
}

@Component({
  selector: 'sa-kl-components',
  template: '<ng-content></ng-content>'
})
export class KlComponents implements AfterViewInit {
  @Input() basePath = ''; // optional
  @Input() imagesPath = ''; // optional

  // tslint:disable-next-line:no-output-rename
  @Output('klReady') klReady = new EventEmitter() // optional


  // save the KeyLines service
  private KlComponentsService: KlComponentsService;
  // get the list of the children components
  // http://blog.thoughtram.io/angular/2015/09/03/forward-references-in-angular-2.html
  @ContentChildren(KlComponent)
  private components: KlComponent[];

  // constructor
  constructor(_KlComponentsService: KlComponentsService) {
    this.KlComponentsService = _KlComponentsService
  }

  // lifecycle hooks
  ngAfterViewInit() {
    // console.log('ngAfterViewInit')
    // iterate over the list of children components to create the KeyLines definition of components
    const toCreate: KeyLines.Component[] = this.components.map((component: KlComponent) => component.getHeader());
    this.makeComponents(toCreate);
  }

  // KL instructions
  makeComponents(componentsToCreate: KeyLines.Component[]) {
    // console.log('makeComponents()')

    // use the KeyLines service to create the components
    this.KlComponentsService.create(componentsToCreate, this.basePath, this.imagesPath)
      .then((components: KeyLines.Chart[]) => this.notifyComponents(components))
      .catch((error: any) => error);
  }

  notifyComponents(components: KeyLines.Chart[]) {
    // ensure that we have an array of components
    if (!Array.isArray(components)) {
      components = [components];
    }
    // emit the ready events
    this.klReady.emit(components);
    // for each components registered as children
    // we finalise the set up of the component
    this.components.forEach((component: KlComponent, index: number) => component.setUpComponent(components[index]));
  }
}

