import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core'
import { select, NgRedux } from '@angular-redux/store'
import { Observable } from 'rxjs/Observable'

import * as _ from 'lodash'

import { MapActions } from './map.actions'
import {
  IQuest,
  IInteractQuestion,
  IMapData,
  IFilter,
  TFilterArr,
  IAppState,
  IMapState,
  InteractQuestState
} from '../../app.state'
import * as util from '../../services/util.service'
import * as Texts from '../../texts'
import { COLORSHEX } from '../../constants'

import { InteractActions } from '../interact/interact.actions'
import * as interactService from '../interact/interact.service'
import { mapStateToLocalStorage, clearMapStateInLocalService } from './map-persist.service'
import { AjaxService } from 'app/services/ajax.service'


const trace = util.traceToggle(false)

@Component({
  selector: 'sa-app-int-map-nav',
  templateUrl: './map-nav.component.html',
  styleUrls: ['./map-nav.component.scss']
})
export class MapNavComponent implements OnInit {
  colorsList: any;
  subtitle = Texts.INTERACT_EXPLORE_SUBTITLE
  searchValue: string
  activeGids2: number[]

  mapStore$: Observable<IMapState>

  /** isFloater */
  @Input() isFloater: boolean
  @Input() mapRootId: string
  @Input() selectedUserMap: number

  /** questionnaires */
  questsListOpen = false
  selectedQuestName: string
  questionnairesList: IQuest[]
  selectedQ: IQuest
  @Input() activeQuestId: number | 'do-not-show'
  @Input() set questionnaires(v: IQuest[] | 'do-not-show') {
    if (v === 'do-not-show') { return }
    this.selectedQuestName = this.updateSelectedQuestName(v, this.activeQuestId)
    this.questionnairesList = v
  }

  /** quesitons */
  questionsListOpen = false
  selectedQuestionName: string
  questionsList: IInteractQuestion[]

  activeQQId: number
  @Input() set activeQuestionId(aqqid: number | 'do-not-show') {
    if (aqqid !== 'do-not-show') {

      this.newQuestionSelected = true
      this.activeQQId = aqqid

      const quest = _.filter(this.questionsList, (q) => q.id === aqqid)
      if (!_.isNil(quest) && quest.length > 0) {
        this.selectedQuestionName = quest[0].title
      } else {
        this.selectedQuestionName = 'NA'
      }
    }
  }

  @Input() set questions(v: IInteractQuestion[] | 'do-not-show') {
    if (v === 'do-not-show') { return }
    this.selectedQuestionName = this.updateSelectedQuestionName(v, this.activeQuestionId)
    this.questionsList = _.filter(v, (qest: IInteractQuestion) => qest.active)
  }

  /** Indicate wheter to call fetchMapData or not */
  newQuestionSelected = false
  @Input() set activeGids(gids: number[]) {
    this.activeGids2 = gids
    if (this.newQuestionSelected) {
      this.newQuestionSelected = false
      this.activeGids = gids
      if (this.activeQQId !== undefined) {
        this.ma.fetchMapData(this.mapRootId, this.activeQQId, gids, this.selectedUserMap)
      }
    }
  }


  /** Color By */
  colorbyOpen = false
  colorbyList = ['Structure', 'Role', 'Rank', 'Gender', 'Formal', 'Office', 'ParamA', 'ParamB',
    'ParamC',
    'ParamD',
    'ParamE',
    'ParamF',
    'ParamG',
    'ParamH',
    'ParamI',
    'ParamJ']
  selectedColorby = 'Structure'


  /** Filters */
  filtersOpen = false
  selectedFilters = 'Default'
  nodes$: Observable<IMapData[]>
  filters$: Observable<IFilter[]>
  nodes: IMapData[]
  filters: IFilter[]

  /** Edges Filter */
  edgesFilterOpen = false
  edgesFromFilter$: Observable<number>
  edgesToFilter$: Observable<number>
  showCoreNetwork$: Observable<boolean>

  /** Hide names */
  hideNamesOpen = false
  hideNames$: Observable<boolean>

  /** Uni colors */
  uniColors$: Observable<boolean>

  factorNames$: Observable<any>
  factorsDisplayNameList: any
  // ========================= Static functions ========================================== //
  /**
   * Translate a list of nodes to the possible filters.
   * Randomly assign colors
   */
  static nodesToFitlers = (nodes: IMapData[], filt: IFilter[], colors: any): IFilter[] => {
    const roles = colors != null && colors.roles ? colors.roles : [];
    const offices = colors != null && colors.offices ? colors.offices : [];
    const job_titles = colors != null && colors.job_titles ? colors.job_titles : [];
    const ranksColor = colors != null && colors.ranks ? colors.ranks : [];
    const groups_color = colors != null && colors.groups ? colors.groups : [];

    const param_a_colors = colors != null && colors.factor_as ? colors.factor_as : [];
    const param_b_colors = colors != null && colors.factor_bs ? colors.factor_bs : [];
    const param_c_colors = colors != null && colors.factor_cs ? colors.factor_cs : [];
    const param_d_colors = colors != null && colors.factor_ds ? colors.factor_ds : [];
    const param_e_colors = colors != null && colors.factor_es ? colors.factor_es : [];
    const param_f_colors = colors != null && colors.factor_fs ? colors.factor_fs : [];
    const param_g_colors = colors != null && colors.factor_gs ? colors.factor_gs : [];

    if (ranksColor && ranksColor.length > 0) {
      filt[1].values.forEach(element => {
        ranksColor.forEach(item => {
          if (item[element.name]) {
            element.id = _.toInteger(element.name),
              element.color = item[element.name].color_rgb
          }
        });
      });
    }

    _.forEach(nodes, (n: IMapData) => {
      const role = n.role
      if (role !== undefined) {
        if (_.find(filt[0].values, (e: { name: string, active: boolean }) => e.name === role) === undefined) {

          let value;
          roles.forEach(item => {
            if (item[n.role_id]) {
              value = item[n.role_id];
            }
          });

          filt[0].values.push({ id: n.role_id, name: role, active: false, color: value ? value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
        }
      }

      const office = n.office_name
      if (office !== undefined) {
        if (_.find(filt[3].values, (e: { name: string, active: boolean }) => e.name === office) === undefined) {
          let office_value;
          offices.forEach(item => {
            if (item[n.office_id]) {
              office_value = item[n.office_id];
            }
          });
          filt[3].values.push({ id: n.office_id, name: office, active: false, color: office_value ? office_value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
        }
      }

      const jobTitle = n.job_title
      if (jobTitle !== undefined && jobTitle !=null && jobTitle != "") {
        if (_.find(filt[4].values, (e: { name: string, active: boolean }) => e.name.toString().toLocaleLowerCase().trim() === jobTitle.toString().toLocaleLowerCase().trim()) === undefined) {
          let value;
          job_titles.forEach(item => {
            if (item[n.job_title_id]) {
              value = item[n.job_title_id];
            }
          });

          filt[4].values.push({ id: n.job_title_id, name: jobTitle, active: false, color: value ? value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
        }
      }

      const group = n.group_name
      if (group !== undefined) {
        if (_.find(filt[6].values, (e: { name: string, active: boolean }) => e.name === group) === undefined) {
          let value;
          groups_color.forEach(item => {
            if (item[n.group_id]) {
              value = item[n.group_id];
            }
          });

          filt[6].values.push({ id: n.group_id, name: group, active: false, color: value ? value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
        }
      }

      const paramA = n.param_a
      if (paramA != undefined) {
        if (_.find(filt[7].values, (e: { name: string, active: boolean }) => e.name === paramA) === undefined) {

          let value;
          param_a_colors.forEach(item => {
            if (item[n.param_a_id]) {
              value = item[n.param_a_id];
            }
          });

          filt[7].values.push({ id: n.param_a_id, name: paramA, active: false, color: value ? value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
        }
      }

      const paramB = n.param_b
      if (paramB != undefined) {
        if (_.find(filt[8].values, (e: { name: string, active: boolean }) => e.name === paramB) === undefined) {
          // filt[8].values.push({ name: paramB, active: false, color: Math.round(Math.random() * 16) })
          let value;
          param_b_colors.forEach(item => {
            if (item[n.param_b_id]) {
              value = item[n.param_b_id];
            }
          });

          filt[8].values.push({ id: n.param_b_id, name: paramB, active: false, color: value ? value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
        }
      }
      const paramC = n.param_c
      if (paramC != undefined) {
        if (_.find(filt[9].values, (e: { name: string, active: boolean }) => e.name === paramC) === undefined) {
          let value;
          param_c_colors.forEach(item => {
            if (item[n.param_c_id]) {
              value = item[n.param_c_id];
            }
          });

          filt[9].values.push({ id: n.param_c_id, name: paramC, active: false, color: value ? value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
          // filt[9].values.push({ name: paramC, active: false, color: Math.round(Math.random() * 16) })
        }
      }
      const paramD = n.param_d
      if (paramD != undefined) {
        if (_.find(filt[10].values, (e: { name: string, active: boolean }) => e.name === paramD) === undefined) {
          // filt[10].values.push({ name: paramD, active: false, color: Math.round(Math.random() * 16) })
          let value;
          param_d_colors.forEach(item => {
            if (item[n.param_d_id]) {
              value = item[n.param_d_id];
            }
          });

          filt[10].values.push({ id: n.param_d_id, name: paramD, active: false, color: value ? value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
        }
      }
      const paramE = n.param_e
      if (paramE != undefined) {
        if (_.find(filt[11].values, (e: { name: string, active: boolean }) => e.name === paramE) === undefined) {
          // filt[11].values.push({ name: paramE, active: false, color: Math.round(Math.random() * 16) })
          let value;
          param_e_colors.forEach(item => {
            if (item[n.param_e_id]) {
              value = item[n.param_e_id];
            }
          });

          filt[11].values.push({ id: n.param_e_id, name: paramE, active: false, color: value ? value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
        }
      }
      const paramF = n.param_f
      if (paramF != undefined) {
        if (_.find(filt[12].values, (e: { name: string, active: boolean }) => e.name === paramF) === undefined) {
          // filt[12].values.push({ name: paramF, active: false, color: Math.round(Math.random() * 16) })
          let value;
          param_f_colors.forEach(item => {
            if (item[n.param_f_id]) {
              value = item[n.param_f_id];
            }
          });

          filt[12].values.push({ id: n.param_f_id, name: paramF, active: false, color: value ? value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
        }
      }
      const paramG = n.param_g
      if (paramG != undefined) {
        if (_.find(filt[13].values, (e: { name: string, active: boolean }) => e.name === paramG) === undefined) {
          // filt[13].values.push({ name: paramG, active: false, color: Math.round(Math.random() * 16) })
          let value;
          param_g_colors.forEach(item => {
            if (item[n.param_g_id]) {
              value = item[n.param_g_id];
            }
          });

          filt[13].values.push({ id: n.param_g_id, name: paramG, active: false, color: value ? value.color_rgb : Math.round(Math.random() * 16), isOpen: false })
        }
      }
      const paramH = n.param_h
      if (paramH != undefined) {
        if (_.find(filt[14].values, (e: { name: string, active: boolean }) => e.name === paramH) === undefined) {
          filt[14].values.push({ name: paramH, active: false, color: Math.round(Math.random() * 16) })
        }
      }
      const paramI = n.param_i
      if (paramI != undefined) {
        if (_.find(filt[15].values, (e: { name: string, active: boolean }) => e.name === paramI) === undefined) {
          filt[15].values.push({ name: paramI, active: false, color: Math.round(Math.random() * 16) })
        }
      }
      const paramJ = n.param_j
      if (paramJ != undefined) {
        if (_.find(filt[16].values, (e: { name: string, active: boolean }) => e.name === paramJ) === undefined) {
          filt[16].values.push({ name: paramJ, active: false, color: Math.round(Math.random() * 16) })
        }
      }
    })
    return filt
  }

  /**
   * Translate fitlers to string representation
   */
  static filtersToString = (filters: IFilter[]): string => {
    const filterSnipets = _.map(filters, (f) => MapNavComponent.filterToString(f))
      .filter((f) => f !== '')
    if (filterSnipets.length === 0) { return 'Default' }
    return filterSnipets.join(' > ')
  }
  static filterToString = (filt: IFilter): string => {
    let resArr: any = _.filter(filt.values, (f) => f.active)
    if (resArr.length === 0) { return '' }
    resArr = _.map(resArr, (f: { name: string, active: boolean }) => f.name)
    const stringRep = `${filt.name}: ${resArr.join(',')}`
    return stringRep
  }

  /**
   * Flatten the structure into a list of active values only.
   * Result example: [{filterName: 'Role', filterValue: 'Manager'},
   *                  {filterName: 'Age', filterValue: '41-50'}]
   */
  static filtersFlatten = (filters: IFilter[]): TFilterArr => {
    const filterSnipets = _.flatMap(filters, (filt) => {
      return _.filter(filt.values, (val) => val.active)
        .map((val) => ({ filterName: filt.name, filterValue: val.name }))
    })
    return filterSnipets
  }

  /**
   * filter a bunch of nodes using the data structure return from fitlersFlatten above. Use this
   * function to determine which nodes should be greyed out
   */
  static filterNodes = (filters: IFilter[], nodes: IMapData[]): IMapData[] => {
    const flatFilters = MapNavComponent.filtersFlatten(filters)
    const newNodes = _.map(nodes, (n: IMapData) => {
      n.fi = !MapNavComponent.isNodeVisible(flatFilters, n)
      return n
    })
    return newNodes
  }
  /**
   * A node is visible only if it passes all filters.
   */
  static isNodeVisible = (filters: TFilterArr, node: IMapData): boolean => {
    // tslint:disable-next-line: cyclomatic-complexity
    return _.reduce(filters, (preres, filt) => {
      let res = false
      switch (filt.filterName) {
        case 'Role':
          res = (filt.filterValue === node.role) && preres
          break
        case 'Rank':
          res = (filt.filterValue === (node.rank && node.rank.toString())) && preres
          break
        case 'Gender':
          res = (filt.filterValue === node.gender) && preres
          break
        case 'Office':
          res = (filt.filterValue === node.office_name) && preres
          break
        case 'Job Title':
          res = (filt.filterValue === node.job_title) && preres
          break
        case 'Age':
          res = (filt.filterValue === node.age.toString()) && preres
          break
        case 'Structure':
          res = (filt.filterValue === node.group_name) && preres
          break
        case 'ParamA':
          res = (filt.filterValue === node.param_a) && preres
          break
        case 'ParamB':
          res = (filt.filterValue === node.param_b) && preres
          break
        case 'ParamC':
          res = (filt.filterValue === node.param_c) && preres
          break
        case 'ParamD':
          res = (filt.filterValue === node.param_d) && preres
          break
        case 'ParamE':
          res = (filt.filterValue === node.param_e) && preres
          break
        case 'ParamF':
          res = (filt.filterValue === node.param_f) && preres
          break
        case 'ParamG':
          res = (filt.filterValue === node.param_g) && preres
          break
        case 'ParamH':
          res = (filt.filterValue === node.param_h) && preres
          break
        case 'ParamI':
          res = (filt.filterValue === node.param_i) && preres
          break
        case 'ParamJ':
          res = (filt.filterValue === node.param_j) && preres
          break
      }
      return res
    }, true)
  }
  // ========================= Statics end ========================================== //
  openDropdowns: any[] = [];
  constructor(private ma: MapActions,
    private ajaxsrv: AjaxService,
    private interactActions: InteractActions,
    private ngRedux: NgRedux<IAppState>,
    private me: ElementRef
  ) { }

  ngOnInit() {
    this.getColors();
    this.nodes$ = this.ngRedux.select([this.mapRootId, 'nodes'])
    this.filters$ = this.ngRedux.select([this.mapRootId, 'filters'])
    this.edgesFromFilter$ = this.ngRedux.select([this.mapRootId, 'edgesFromFilter'])
    this.edgesToFilter$ = this.ngRedux.select([this.mapRootId, 'edgesToFilter'])
    this.showCoreNetwork$ = this.ngRedux.select([this.mapRootId, 'showCoreNetwork'])
    this.hideNames$ = this.ngRedux.select([this.mapRootId, 'hideNames'])
    this.uniColors$ = this.ngRedux.select([this.mapRootId, 'uniColors'])
    this.mapStore$ = this.ngRedux.select([this.mapRootId])
    this.factorNames$ = this.ngRedux.select([this.mapRootId, 'factorNames'])

    this.nodes$.subscribe((v) => {
      if (v === undefined || v.length === 0) { return }
      this.nodes = v
      this.ma.updateFitlers(this.mapRootId)
    })

    this.filters$.subscribe((f) => {
      this.filters = f;
    })

    this.factorNames$.subscribe((v) => {
      if (v !== undefined) {
        this.factorsDisplayNameList = v
      }
    })

    /** Save the entire redux state (of the map) to the local storage */
    this.mapStore$.subscribe((mapState) => {
      mapStateToLocalStorage(mapState)
    })

    if (this.isFloater) {
      this.addDragFunctionalityToMapNav(this.mapRootId)
    }

    this.selectedQ = _.find(this.questionnairesList, (q: IQuest) => q.id === this.activeQuestId)
    if (this.activeQuestId === -1 || this.selectedQ === undefined) {
      return this.questionsList[0]
    }

  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.me.nativeElement.contains(event.target)) {
      this.closeAllDropdowns();
    }
  }

  closeAllDropdowns() {
    this.filters.forEach(element => {
      if (element.name.toString().toLocaleLowerCase() != "age" && element.name.toString().toLocaleLowerCase() != "gender") {
        element.values.forEach(element => {
          element.isOpen = false;
        });
      }
    });
    // this.openDropdowns = this.openDropdowns.map(() => false);
  }

  toggleDropdown(inx, finx) {
    const initial = this.filters[inx].values[finx].isOpen;
    if (this.filters && this.filters[inx] && this.filters[inx].values) {
      this.filters[inx].values.forEach((value) => {
        value.isOpen = false;
      });
    }
    this.filters[inx].values[finx].isOpen = !initial;
  }

  getColors() {
    this.ajaxsrv.get('/v3/get_unassigned_group_colors', {}, (res) => {
      const gropColors = JSON.parse(res['_body'])
      this.colorsList = gropColors.colors;
    })
  }

  selectItem(item: any, id: any, option: any, inx: any) {

    switch (option) {
      case "Job Title":
        option = "job_titles";
        break;
      case "Structure":
        option = "group";
        break;
      case "ParamA":
        option = "factor_a";
        break;
      case "ParamB":
        option = "factor_b";
        break;
      case "ParamC":
        option = "factor_c";
        break;
      case "ParamD":
        option = "factor_d";
        break;
      case "ParamE":
        option = "factor_e";
        break;
      case "ParamF":
        option = "factor_f";
        break;
      case "ParamG":
        option = "factor_g";
        break;
      default:
        break;
    }
    let params = { obj: option.toString().toLowerCase(), oid: id, color_id: item[0] }
    this.ajaxsrv.post('/v3/update_color', params, (res) => {
      const body = JSON.parse(res['_body'])
      if (res.status == 200) {
        if (this.filters && this.filters[inx] && this.filters[inx].values) {
          this.filters[inx].values.forEach((value) => {
            value.isOpen = false;
          });
        }
        this.resetMap();
      }
    });
  }

  factorDisplayName = (factor: string) => {
    if (this.factorsDisplayNameList[factor] !== undefined)
      return this.factorsDisplayNameList[factor]
    return factor
  }

  questClicked = (qid: number) => {
    const quest = _.find(this.questionnairesList, (q) => q.id === qid)
    this.selectedQuestName = this.updateSelectedQuestName(this.questionnairesList, qid)
    this.interactActions.questSelected(qid)
  }

  isQuestCompleted = () => {
    return (this.selectedQ !== undefined && this.selectedQ.state === InteractQuestState.completed)
  }

  questionClicked = (qqid: number) => {
    this.newQuestionSelected = true
    this.interactActions.setActiveQuestion(qqid)
    interactService.updateUrlParams('activeQuestionId', qqid)
    const quest = _.filter(this.questionsList, (q) => q.id === qqid)
    if (!_.isNil(quest) && quest.length > 0) {
      this.selectedQuestionName = quest[0].title
      this.activeQQId = qqid
      if (this.activeQQId !== undefined) {
        this.ma.fetchMapData(this.mapRootId, this.activeQQId, this.activeGids2, this.selectedUserMap)
      }
    } else {
      this.selectedQuestionName = 'NA'
    }
  }

  updateSelectedQuestName = (quests: IQuest[], qid): string => {
    if (quests.length === 0) { return 'NA' }
    const selectedQuest: IQuest =
      _.find(quests, (q: IQuest) => q.id === qid)
    if (this.activeQuestId === -1 ||
      selectedQuest === undefined) {
      return quests[0].name
    }
    return selectedQuest.name
  }

  updateSelectedQuestionName = (quests: IInteractQuestion[], qid): string => {
    if (quests.length === 0) { return 'NA' }
    const selectedQuestion: IInteractQuestion =
      _.find(quests, (q: IInteractQuestion) => q.id === qid)
    if (this.activeQuestionId === -1 || selectedQuestion === undefined) {
      const activeQuests = _.filter(quests, q => q.active)
      return (activeQuests.length > 0 ? activeQuests[0].title : 'NA')
    }
    return selectedQuestion.title
  }

  colorbyClicked = (colorby: string) => {
    trace('In colorbyClicked() - colorby: ', colorby)
    this.selectedColorby = colorby
    this.ma.colorbySelected(this.mapRootId, colorby, this.filters)
  }

  filterToggled = (inx: number) => {
    this.filters[inx].open = !this.filters[inx].open;
    this.ma.reColorMap(this.mapRootId)
  }

  filterItemToggled = (inx: number, finx: number) => {
    this.filters[inx].values[finx].active = !this.filters[inx].values[finx].active
    this.selectedFilters = MapNavComponent.filtersToString(this.filters)
    this.ma.reColorMap(this.mapRootId)
  }

  hexColor = (colorInx: any, filter_name: any) => {
    if (filter_name.toString().trim().toLowerCase() != 'age' && filter_name.toString().trim().toLowerCase() != 'gender') {
      return "#" + colorInx;
    }
    return COLORSHEX[colorInx]
  }
  searchTriggered = (event) => {
    const searchText = event.target.value
    this.ma.searchNode(this.mapRootId, searchText)
  }

  clearSearch = () => {
    const search = document.getElementById('int-map-search-line-id')
    this.searchValue = ''
    this.ma.searchNode(this.mapRootId, '')
  }

  groupAll = () => {
    this.ma.groupAll(this.mapRootId)
  }

  toggleHideNames = ($event) => {
    this.ma.toggleHideShowNames(this.mapRootId, $event)
  }

  toggleUniColors = ($event) => {
    this.ma.toggleUniColors(this.mapRootId, $event)
  }

  edgeFromFilterChanged = ($event) => {
    this.ma.changeEdgesFilter(this.mapRootId, $event, 'from')
  }

  edgeToFilterChanged = ($event) => {
    this.ma.changeEdgesFilter(this.mapRootId, $event, 'to')
  }

  toggleCoreNetwork = () => {
    this.ma.toggleCoreNetwork(this.mapRootId)
  }

  resetMap = () => {
    clearMapStateInLocalService()
    location.reload()
  }

  goToParticipants = () => {
    this.interactActions.goToParticipants()
  }

  /**
   * Make the explore filter floater div draggable.
   */
  addDragFunctionalityToMapNav = (mapRootId: string) => {

    const elmnt = document.getElementById(`${mapRootId}-nav-floater`)
    if (_.isNil(elmnt)) { return }

    let pos1 = 0
    let pos2 = 0
    let pos3 = 0
    let pos4 = 0
    document.getElementById(`${mapRootId}-nav-floater-header`).onmousedown = dragMouseDown

    function dragMouseDown(e) {
      e = e || window.event
      e.preventDefault()
      // get the mouse cursor position at startup:
      pos3 = e.clientX
      pos4 = e.clientY
      document.onmouseup = closeDragElement
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag
    }

    function elementDrag(e) {
      e = e || window.event
      e.preventDefault()
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX
      pos2 = pos4 - e.clientY
      pos3 = e.clientX
      pos4 = e.clientY
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + 'px'
      elmnt.style.left = (elmnt.offsetLeft - pos1) + 'px'
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null
      document.onmousemove = null
    }
  }
}
