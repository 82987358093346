import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-bulk-option',
  templateUrl: './bulk-option.component.html',
  styleUrls: ['./bulk-option.component.scss']
})
export class BulkOptionComponent implements OnInit {
  @Input() list: any[]
  @Output() optionSelected = new EventEmitter<{ method: string, name: string }>()
  showDropDown: boolean = false

  constructor() {}

  optionClicked(method: string, name: string) {
    this.optionSelected.emit({ method: method, name: name })
  }

  ngOnInit() {}
}
