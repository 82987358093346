﻿import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material';
import { MatSliderModule } from '@angular/material/slider';

import { NgReduxModule, NgRedux, DevToolsExtension } from '@angular-redux/store';
import { NgReduxRouter } from '@angular-redux/router';
import { RouterModule } from '@angular/router';

import { routes } from './routes';

import { AppComponent } from './app.component';
import { TimeSpentComponent } from './components/timespent/timespent.component'
import { EmailsComponent } from './components/timespent/emails.component'
import { MeetingsComponent } from './components/timespent/meetings.component'
import { DynamicsComponent } from './components/dynamics/dynamics.component';
import { InterfacesComponent } from './components/interfaces/interfaces.component';
import { SimulatorComponent } from './components/simulator/simulator.component';
import { AlertsComponent } from './components/alerts/alerts.component'
import { LoginComponent } from './components/login/login.component'
import { LogoutComponent } from './components/login/logout.component'
import { MainMenuComponent } from './components/main-menu/main-menu.component'
import { NavComponent } from './components/nav/nav.component'
import { EMatrixComponent } from './components/ematrix/ematrix.component'
import { ProdStatsComponent } from './components/prod-stats/prod-stats.component'
import { BreadCrumbsComponent } from './components/breadcrumbs/breadcrumbs.component'
import { LeaderboardHeaderComponent } from './components/leaderboard/leaderboard-header.component'
import { EmpsTimeSpentComponent } from './components/leaderboard/emps-time-spent.component'
import { LeaderboardCollaborationHeaderComponent } from './components/leaderboard-collaboration/leaderboard-collaboration-header.component'
import { EmployeeScoresComponent } from './components/leaderboard-collaboration/employee-scores.component'
import { GroupRelationsComponent } from './components/leaderboard-collaboration/groups-relation.component'

import { EmailsActions } from './components/timespent/emails.actions'
import { MeetingsActions } from './components/timespent/meetings.actions'
import { TimeSpentActions } from './components/timespent/timespent.actions'
import { DynamicsActions } from './components/dynamics/dynamics.actions'
import { InterfacesActions } from './components/interfaces/interfaces.actions'
import { AppActions } from './app.actions'
import { NavActions } from './components/nav/nav.actions'
import { AlertActions } from './components/alerts/alerts.actions'

import { rootReducer } from './app.reducer'
import { IAppState, INITIAL_STATE } from './app.state'

import { AjaxService } from './services/ajax.service'
import { DataService } from './services/data.service'
import { LogoutService } from './services/logout.service'

import { KlModule } from './components/keylines/kl.module'

import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { TimePickerWidgetComponent } from './components/time-picker-widget/time-picker-widget.component';
import { TogglerComponent } from './components/toggler/toggler.component';
import { ProdStatsCollaborationComponent } from './components/prod-stats-collaboration/prod-stats-collaboration.component';
import { BarsTableComponent } from './components/bars-table/bars-table.component';

import { SettingsModule } from './components/settings/settings.module'
import { InteractModule } from './components/interact/interact.module'
import { FormattersModule } from './formatters/formatters.module'
import { WidgetsModule } from './components/widgets/widgets.module'
import { MapModule } from './components/map/map.module'
;
import { StatusModalComponent } from './components/status-modal/status-modal.component'
;
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
;
import { UserMapComponent } from './components/user-map/user-map.component';
import { BasicMapComponent } from './components/basic-map/basic-map.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
// ...
@NgModule({
  declarations: [
    AppComponent,
    EmailsComponent,
    MeetingsComponent,
    TimeSpentComponent,
    DynamicsComponent,
    SimulatorComponent,
    LoginComponent,
    LogoutComponent,
    MainMenuComponent,
    NavComponent,
    EMatrixComponent,
    ProdStatsComponent,
    ComingSoonComponent,
    TimePickerWidgetComponent,
    BreadCrumbsComponent,
    LeaderboardHeaderComponent,
    EmpsTimeSpentComponent,
    LeaderboardCollaborationHeaderComponent,
    EmployeeScoresComponent,
    GroupRelationsComponent,
    TogglerComponent,
    ProdStatsCollaborationComponent,
    InterfacesComponent,
    BarsTableComponent,
    AlertsComponent,
    StatusModalComponent ,
    UserMapComponent ,
    BasicMapComponent],
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    NgReduxModule,
    SettingsModule,
    InteractModule,
    FormattersModule,
    WidgetsModule,
    KlModule,
    MapModule,
    MatSliderModule,
    BrowserAnimationsModule,
    MatDialogModule,
  ],
  providers: [
    NgReduxRouter,
    EmailsActions,
    MeetingsActions,
    TimeSpentActions,
    DynamicsActions,
    InterfacesActions,
    AlertActions,
    AppActions,
    AppComponent,
    AjaxService,
    LogoutService,
    NavActions,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>, private devTools: DevToolsExtension, ngReduxRouter: NgReduxRouter) {

    const storeEnhancers = this.devTools.isEnabled() ? [ this.devTools.enhancer() ] : [];

    ngRedux.configureStore(rootReducer, INITIAL_STATE, [], storeEnhancers);

    ngReduxRouter.initialize();

  }
}
